import { injectCommonHeaderContext } from '@shein-aidc/bs-common-header-laptop'

export const useHeader = () => {
  const commonHeaderContext = injectCommonHeaderContext()

  let headerDom: Element | null = null

  const showHeader = async () => {
    if (commonHeaderContext?.isShow?.value) {
      // @ts-ignore
      window.__noNeedNavScoll = true
      return {
        callback() {
          // @ts-ignore
          window.__noNeedNavScoll = false
        },
      }
    }
    if (!headerDom) headerDom = document.querySelector('.bsc-common-header')
    let isMouseenter = false
    let onMouseenter: any = () => {
      isMouseenter = true
    }
    if (headerDom) {
      // @ts-ignore
      window.__noNeedNavScoll = true
      headerDom.addEventListener('mouseenter', onMouseenter)
    }
    commonHeaderContext?.show()

    await new Promise(r => setTimeout(r, 300))

    return {
      callback() {
        // @ts-ignore
        window.__noNeedNavScoll = false
        if (headerDom) {
          headerDom.removeEventListener('mouseenter', onMouseenter)
          onMouseenter = null
        }
        if (isMouseenter) return
        hideHeader()
      },
    }
  }

  const hideHeader = () => {
    commonHeaderContext?.hide()
  }

  return {
    showHeader,
    hideHeader,
  }
}
