import { removeCookie } from '@shein/common-function'

const COOKIE_KEY = 'uswitch'

function getCurMainDomain() {
  // us.shein.com => shein.com
  // us.shein.co => shein.co
  // m.shein.com => shein.com
  const domain = window.location.hostname
  const domainArr = domain.split('.')
  domainArr.shift()
  return domainArr.join('.')
}

export const logoutHandlePersonalizedSwitch = () => {
  removeCookie({ key: COOKIE_KEY, domain: getCurMainDomain() })
}


const eachTypeMap = {
  hasClass({ elements, className }){
    return elements.some((element)=>{
      if(!element.classList)return
      return element.classList.contains(className)
    })
  },
  addClass({ elements, className }){
    return elements.forEach((element)=>{
      if(!element.classList)return
      element.classList.add(className)
    })
  },
  removeClass({ elements, className }){
    return elements.forEach((element)=>{
      if(!element.classList)return
      element.classList.remove(className)
    })
  },
  setCSS({ elements, styleProp, styleValue }){
    return elements.forEach((element)=>{
      if(!element.style)return
      element.style[styleProp] = styleValue
    })
  },
  getCSS({ elements, styleProp }){
    return window.getComputedStyle(elements[0]).getPropertyValue(styleProp)
  },
  getOffset({ elements }){
    return elements?.[0].getBoundingClientRect()
  },
  forEach({ elements, cb }){
    return elements.forEach(cb)
  },
}

/**
 * @param {object} options
 * @parameter selector类型为string或Array<element>,用于告知当前需要查找的元素
 * @parameter cb类型为Function,用于传入事件回调或者遍历元素使用的回调函数
 * @parameter type类型为string,用于传入事件名或者需要的操作名称,例如:click或者addClass或者setCSS/getCSS
 * @parameter className类型为string,用于传入需要添加/删除/查找的类名
 * @parameter styleProp类型为string,用于传入需要修改/读取的样式属性名
 * @parameter styleValue类型为string,用于传入需要修改的样式属性值
 * @returns 返回undefined或者查找结果boolean或者读取样式结果string
 */

export function eachHandler(options){
  const newOptions = { ...options }
  const { selector, cb, type } = newOptions
  if(typeof options === 'function'){
    document.addEventListener('DOMContentLoaded', options)
    return
  }
  if(typeof options !== 'object' || !selector) return


  let elements = selector
  if(typeof selector === 'string'){
    elements = [...document.querySelectorAll(selector)]
  }
  if(typeof selector === 'object' && !Array.isArray(selector)){
    elements = [selector]
  }
  if(!type && cb){
    return elements.forEach(cb)
  }

  newOptions.elements = elements

  return eachTypeMap[type] ? eachTypeMap[type](newOptions) : elements.forEach((element)=>{
    element?.addEventListener(type, cb)
  })
}
