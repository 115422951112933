<template>
  <div
    class="bs-cate-wrap"
    :style="styleWrap">
    <TopNav
      ref="topNavRef"
      :showSkeleton="watchData"
      :cateNavs="cateNavs"
      :navCate="curNavCate"
      :activeNavId="activeNavId"
      :isAllHover="isAllHover"
      :hoverEntryNav="hoverEntryNav"
      :allCateText="allCateText"
      @mouseenter="getCateGory"
      @mouseenterpanel="handleDropView(true, 'topNav')"
      @mouseleave="handleDropView(false, 'topNav')"
      @navClick="(item) => emits('navClick',item)"
    />
    <ClientOnly>
      <CateDropdown
        ref="dropdownRef"
        :cateNavs="cateNavs"
        :tabNav="exposeHoverTab"
        :navCate="curNavCate"
        :rightCate="rightCate"
        :isAllHover="isAllHover"
        :loading="loading"
        @mouseenter="getCateGory"
        @mouseleave="handleDropView(false, 'drop')"
        @mouseenterpanel="handleDropView(true, 'drop')"
        @clickCate="(item) => emits('clickCate',item)"
      />
    </ClientOnly>
  </div>
</template>

<script name="BCategory" setup lang="ts">
// 数据源：
import { ref, defineEmits, onMounted, onUnmounted, provide, watch } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../common/dataSource'
import type { AS_Category, DS_Category } from '../../types'
import { ClientOnly } from '@sheinfe/vue-client-only'
import TopNav from './components/TopNav.vue'
import CateDropdown from './components/CateDropdown.vue'

import { getQueryString, throttle } from '@shein/common-function'
import { useData, isBrowser } from './composables/useData'
import { provideCutImg } from '../../common/hooks/useCutImg'

const appConfigs = useAppConfigs()
provideCutImg(appConfigs)

const emits = defineEmits(['noData', 'pageStatus', 'clickCate', 'navClick', 'changeScrollBar'])

interface IProps {
  fsData?: DS_Category.LAPTOP_FS_DATA
  dataSource?: DS_Category.DataSource
  analysisSource?: AS_Category.AnalysisSource
  getabtest?: Function
  locked?: boolean
  language?: Object
  allCateText?: string
  watchData?: boolean
}
const props = withDefaults(defineProps<IProps>(),{
  locked: true,
  watchData: false,
})
const apis = getSource(props.dataSource, appConfigs)
// 数据源：END
// const channelId = ref(10)

const { cateNavs, rightCate, curNavCate, loading, getCateGoryData, initNavs } = useData(apis, props, emits)
const topNavRef = ref<any>(null)
const dropdownRef = ref<any>(null)
const isAllHover = ref(false)
const activeNavId = ref()
const isNavHover = ref<boolean>(false)
const isDropdwonHover = ref<boolean>(false)
const hoverEntryNav = ref('')
const exposeHoverTab = ref<any>({index:'', name:''})

if(props.watchData){
  watch(() => props.fsData, (data) => {
    initNavs(data)
  })
}

const resetDropdown = throttle({
  func:(item) => {
    if (dropdownRef.value) {
      dropdownRef.value.scrollTo(item)
    }
  },
  wait: 60,
  options: {
    leading: false,
  },
})

const getCateGory = function(item, source){
  const isFromNav = source === 'topNav'
  if(isFromNav) {
    isAllHover.value = !item
    hoverEntryNav.value = item ? item.id : ''
    exposeHoverTab.value = item ? {index: item.index, name: item.categoryName} : {index: -1, name: 'All'}
  } else{
    hoverEntryNav.value = ''
  }
  if (isAllHover.value && !item) {
    // all category mouse enter
    item = cateNavs.value[0]
  }
  getCateGoryData(item, false)
  if (isFromNav) {
    resetDropdown(item)
  }

}

const styleWrap = ref({})
let scrollbarWidth = 0
const triggerDrop = throttle({
  func: () => {
    const isShowDrop = isNavHover.value || isDropdwonHover.value
    // console.log('triggerDrop', isShowDrop);

    if(props.locked && isBrowser){
      const overflowY = isShowDrop ? 'hidden': ''
      // scrollbarWidth = window.innerWidth - document.body.clientWidth
      const marginRight = `${isShowDrop ? scrollbarWidth: 0}px`
      // Object.assign(styleWrap.value, { '--scroll-bar-width': marginRight })
      const body = document.body
      body.style.overflowY = overflowY
      body.style.paddingRight = marginRight
      // emits('changeScrollBar', marginRight)
    }
    if (isShowDrop) {
      styleWrap.value['--layout-top']= topNavRef.value?.getLayoutTop()
      // hoverEntryNav.value = curNavCate.value.id
      dropdownRef.value.showCate()
    } else {
      hoverEntryNav.value = ''
      isAllHover.value = false
      dropdownRef.value.closeCate()
    }
  },
  wait: 50,
  options: {
    leading: false,
  },
})

function handleDropView(isShow, type) {
  if (type === 'topNav') {
    isNavHover.value = isShow
  } else {
    isDropdwonHover.value = isShow
  }
  triggerDrop()
}

let observer
function watchBodyScroll(){
  observer = new MutationObserver(() => {
    const isBodyHidden = document.body.style.overflowY === 'hidden'
    const marginRight = isBodyHidden ? scrollbarWidth : 0
    topNavRef.value?.resetNavPosition(scrollbarWidth,isBodyHidden)
    emits('changeScrollBar', `${marginRight}px`)
  })
  observer.observe(document.body, {
    childList: false, // 观察目标子节点的变化，是否有添加或者删除
    attributes: true, // 观察属性变动
    subtree: false, // 观察后代节点，默认为 false
  })
}

function unWatchBodyScroll(){
  observer.disconnect()
  observer = null
}

const splitFlag = '_!_'
onMounted(() => {
  emits('pageStatus', cateNavs.value?.length > 0 ? '1' : '0')
  const isCategoryJump = getQueryString({ key: 'categoryJump' })
  scrollbarWidth = window.innerWidth - document.body.clientWidth
  Object.assign(styleWrap.value, { '--scroll-bar-width': `${scrollbarWidth}px` })
  watchBodyScroll()
  if (isCategoryJump) {
    const [navId, scrollLeft] = isCategoryJump.split(splitFlag)
    activeNavId.value = decodeURIComponent(navId)
    topNavRef.value?.scrollToView(activeNavId.value, scrollLeft)
  }
  if(topNavRef.value){
    styleWrap.value['--layout-top']= topNavRef.value.getLayoutTop()
  }
  const firstNode = cateNavs.value?.[0]
  if (firstNode) {
    let callback: Function = setTimeout
    if(typeof requestIdleCallback === 'function'){
      callback = requestIdleCallback
    }
    callback(() => {
      getCateGoryData(firstNode, true)
    })
  }
})

onUnmounted(() => {
  unWatchBodyScroll()
})

// 埋点源：
import { useAnalysis } from '../../common/analysisSource'
const analysisInstance = useAnalysis(props.analysisSource)

// console.log(analysisInstance.getExposeInstance());

provide('analysisInstance', analysisInstance)
provide('getJumpFlag', function getJumpFlag(id) {
  const scrollLeft = topNavRef.value?.getScrollLeft() || 0
  const navId = id || curNavCate.value.id
  const categoryJump = `${navId}${splitFlag}${scrollLeft}`
  return { categoryJump }
})
// 埋点源：END

// 内置路由：
// appConfigs.$router.push({ path: `/somepath`, query: { test: 123 } })
// 内置路由：END
</script>
<style>
.bs-cate-wrap {
  position: relative;
  font-family: 'Muli', 'Arial', 'Helvetica', sans-serif;
}

/* body::-webkit-scrollbar {
  width: 25px;
  height: 5px;
}
body::-webkit-scrollbar-track {
  background-color: #fff;
}
body::-webkit-scrollbar-thumb {
  background-color: #ff0000;
  border-radius: 5px;
} */

@media (max-width: 1440px) {
  .bs-cate_one {
    padding-right: 20px;
  }

  .bs-cate-type_title {
    padding-left: calc(100vw * 18 / 1440);
  }

  .bs-cate_two {
    min-width: 295px;
    max-width: 24vw;
    padding: 0 20px;
  }

  .bs-cate_three {
    padding: 0 20px;
    max-width: 54vw;
  }
}

@media (max-width: 1920px) and (min-width: 1440px) {
  .bs-cate_one {
    padding-right: calc((100vw - 1080px) / 16);
  }

  .bs-cate-type_title {
    padding-left: calc(100vw * 20 / 1600);
  }
  .bs-cate-type_img-link {
    flex: 0 0 calc(100% / 7);
  }
  .bs-cate-type_img {
    width: calc(82px - (1920px - 100vw) / 26.67);
    height: calc(82px - (1920px - 100vw) / 26.67);
  }

  .bs-cate_two {
    max-width: 26vw;
    padding: 0 calc(calc((100vw - 1080px) / 16));
  }

  .bs-cate_three {
    max-width: 54vw;
    padding: 0 calc(calc((100vw - 1080px) / 16));
  }
}

@media (min-width: 1920px) {
  .bs-cate_one {
    padding-right: calc((100vw - 1080px) / 16);
  }

  .bs-cate_two,
  .bs-cate_three {
    padding: 0 calc(calc((100vw - 1080px) / 16));
    box-sizing: content-box;
  }

  .bs-cate-type_title {
    padding-left: calc(100vw * 19 / 1920);
  }
  .bs-cate-type_img-link {
    flex: 0 0 calc(100% / 7);
  }
  .bs-cate-type_img {
    width: 82px;
    height: 82px;
  }

  .bs-cate_two {
    max-width: 401px;
  }

  .bs-cate_three {
    max-width: 930px;
  }
}
</style>
