import transfromAndCutImg from '../transferImg'
import { provide, inject } from 'vue'

const cutImgKey = Symbol('cutimg')

function cutImg(RESOURCE_SDK, imgUrl, designWidth, exp, ) {
  const { deviceData = '', isSupportWeb = '', isSupprotCut = false, sceneMap = {} } = RESOURCE_SDK || {}
  if (!isSupportWeb) return imgUrl
  const cutData = {
    deviceData,
    isSupportWebp: Boolean(isSupportWeb),
    isSupprotCut,
    imgUrl,
    designWidth: Number(designWidth),
    sceneMap,
    exp,
  }

  return transfromAndCutImg(cutData)
}

export function provideCutImg(appConfigs) {

  provide(cutImgKey, (imgUrl, designWidth, exp) => {
    const { RESOURCE_SDK } = appConfigs?.$envs || {} 
    return cutImg(RESOURCE_SDK, imgUrl, designWidth, exp)
  })
}

export function getCutImg() {
  return inject(cutImgKey)
}
