<template>
  <div
    class="association-words"
    :class="{
      'association-words__old': !isNewSuggestUI,
    }"
  >
    <div class="association-half">
      <!-- correction -->
      <div
        v-if="correctionNum"
        class="association-words-item j-association-words-item"
        :class="[
          'correction',
          {
            active: itemActiveIndex == 0,
            correction__old: !isNewSuggestUI,
            'association-words-item__old': !isNewSuggestUI,
          },
        ]"
        @click="
          event =>
            goSearchPage(event, {
              keyword: correction,
              result_type: 8,
              position: 1,
            })
        "
        @mouseenter="handleCorrectionMouseEnter"
        @mouseleave="handleCorrectionMouseLeave"
      >
        <Icon
          v-if="associationNewpresearchicon"
          name="sui_icon_search_black_14px"
          size="14px"
          class="popular-search-icon"
        />
        <span
          :class="{
            active: isNewSuggestUI,
          }"
        >{{ language.SHEIN_KEY_PC_16870 }}: </span>{{ correction }}
      </div>
      <!-- popular -->
      <div
        v-for="(item, index) in associationWords.word"
        :key="'popular' + index"
        class="association-words-item j-association-words-item"
        :class="{
          active: itemActiveIndex == correctionNum + categoryNum + index,
          'association-words-item__old': !isNewSuggestUI,
          colorIntensify: associationNewpresearchlenovo,
        }"
        @click="
          event =>
            goSearchPage(event, {
              wordOriginRecord: {
                word_id: item.word_id,
                trace_id: item.trace_id,
              },
              keyword: item.keywords,
              result_type: correctionNum ? 8 : 4,
              position: correctionNum + categoryNum + index + 1,
              tag: item.tag,
              wordTagType: item?.wordTagType,
            })
        "
        @mouseenter="handlePopularMouseEnter(index)"
        @mouseleave="handlePopularMouseLeave"
      >
        <Icon
          v-if="associationNewpresearchicon"
          name="sui_icon_search_black_14px"
          size="14px"
          class="popular-search-icon"
        />
        <div v-html="item.transformed"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'AssociationNew',
  components: {
    Icon,
  },
  props: {
    correctionNum: {
      type: Number,
      default: 0,
    },
    categoryNum: {
      type: Number,
      default: 0,
    },
    popularNum: {
      type: Number,
      default: 0,
    },
    correction: {
      type: String,
      default: '',
    },
    associationWords: {
      type: Object,
      default: () => ({}),
    },
    itemActiveIndex: {
      type: Number,
      default: -1,
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    isNewSuggestUI: {
      type: Boolean,
      default: false,
    },
    associationNewpresearchlenovo: {
      type: Boolean,
      default: false,
    },
    associationNewpresearchicon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['goSearchPage', 'onItemActiveIndex'],
  data() {
    return {
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    handlePopularMouseEnter(index){
      const itemActiveIndex = this.correctionNum + this.categoryNum + index
      this.$emit('onItemActiveIndex', itemActiveIndex)
    },
    handlePopularMouseLeave(){
      const itemActiveIndex = -1
      this.$emit('onItemActiveIndex', itemActiveIndex)
    },
    handleCorrectionMouseEnter() {
      const itemActiveIndex = 0
      this.$emit('onItemActiveIndex', itemActiveIndex)
    },
    handleCorrectionMouseLeave() {
      const itemActiveIndex = -1
      this.$emit('onItemActiveIndex', itemActiveIndex)
    },
    goSearchPage(event, params) {
      this.$emit('goSearchPage', {
        event,
        ...params,
      })
    },
  },
}
</script>

<style lang="less">
.association-words {
  padding: 14px 8px;
}
.association-words__old {
  padding: 0;
}
.association-words-title {
  padding: 18px 0 12px 10px;
  font-size: 14px;
  font-weight: bold;
  /* rw:begin */
  font-family: 'Adieu';
}
.association-words-title__old {
  padding: 16px 12px 0;
  height: 38px;
}

.association-words-item {
  display: flex;
  position: relative;
  line-height: 24px;
  white-space: nowrap;
  padding: 0 10px;
  overflow: hidden;
  font-size: 13px;
  font-family: Arial;
  cursor: pointer;
  text-overflow: ellipsis;
  color: #000;
  font-weight: 700;
  /* rw:begin */
  color: #fc4070;
  span.match-text {
    font-weight: 400;
    color: #767676;
  }
  &.active {
    background-color: #edeef0;
    /* rw:begin */
    background-color: #fff1f8;
  }

  &:has(.association-attr-item:hover) {
    background-color: #fff;
  }

  &.correction {
    line-height: 24px;
    white-space: normal;
    // ! 这里需要注意：纠错词的active是【did you mean:】 而不是 匹配到的词所以样式是反过来的
    color: #222222;
    font-weight: normal;
    font-size: 13px;
    font-weight: 700;
    font-family: Arial;
    /* rw:begin */
    color: #fc4070;
    span.active {
      color: #666;
      font-size: 13px;
      font-weight: 400;
      font-family: Arial;
    }
  }
  &.correction__old {
    line-height: 15px;
    padding: 12px;
    white-space: normal;
    color: #222;
    font-weight: 700;
    span.active {
      color: #222;
      font-weight: 700;
    }
  }
}

.association-words-item.colorIntensify {
  color: #767676;
  font-weight: 400;
  span.match-text {
    font-weight: 700;
    color: #000;
  }
}
.association-words-item__old {
  position: relative;
  padding-left: 12px;
  line-height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  overflow: hidden;
  color: #222;
  font-weight: bold;
  cursor: pointer;
  text-overflow: clip;
  span.active {
    color: #666;
    font-weight: normal;
  }
  &.active {
    background-color: #edeef0;
  }
  &.correction {
    line-height: 15px;
    padding: 12px;
    white-space: normal;
    span.active {
      color: #222;
    }
  }
  &:not(.correction) {
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 100%;
      top: 0;
      right: 0;
      background-image: linear-gradient(90deg, transparent, white);
    }
  }
}
.association-item-warp {
  display: flex;
  align-items: center;
  width: 100%;
}
.popular-search-icon {
  display: flex !important; /* stylelint-disable-line declaration-no-important */
  align-items: center;
  margin-right: 6px;
  color: #000 !important; /* stylelint-disable-line declaration-no-important */
}
.association-item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.association-recent {
  margin-left: 4px;
  padding: 1px 4px;
  border: 0.5px solid;
  color: #f83;
  font-weight: 400;
  font-size: 12px;
  height: 16px;
  line-height: 13px;
  border-radius: 2px;
  &.recnet-RW {
    color: #f03016;
  }
}
.association-tag-icon {
  margin-left: 4px;
  .tag-icon();
  &.association-tag-trend {
    &.icon-RW {
      background-image: url('https://img.shein.com/images3/2023/11/28/28/17011607448e2b0fb11e78f9fc1e7705e66943967c.png');
    }
  }
  &.association-tag-hot {
    background-image: url('https://img.shein.com/images3/2023/10/27/12/1698394178d38f921804439541f3c021cfef646c7c.png');
    &.icon-RW {
      transform: scale(1);
      background-image: url('https://img.shein.com/images3/2023/11/28/bf/1701160764be2c4bb00c77f977e6db58d0e7e32e86.png');
    }
  }
}
.association-hk-tag {
  margin-left: 4px;
  padding: 0 16px 0 4px;
  border: 1px solid;
  color: #9462ff;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  border-radius: 2px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: 2px;
    top: 2px;
    width: 12px;
    height: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/05/16/1c/1715862428b44c046d259ee6df9c1ef75c77525b27.png');
  }
}

.association-attr-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  margin-left: 10px;
}
.association-attr-item {
  box-sizing: content-box;
  height: 12px;
  padding: 4px 8px;
  border-radius: 100px;
  color: var(--sui-color-gray-dark-2, #666);
  font-size: 10px;
  line-height: 12px;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.04);
  &:hover {
    background: var(--sui-color-gray-weak-1, #e5e5e5);
  }

  &.hidden {
    position: absolute;
    opacity: 0;
  }
}
.tag-icon {
  min-width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  transform-origin: center;
  transform: scale(1.25);
  background-image: url('https://img.shein.com/images3/2023/10/27/f5/1698394226527e3792513f865e0b2d6124c9e7a646.png');
}
@media (max-width: 920px) {
  .association-words {
    flex-direction: column;
    padding: 14px 8px;
  }
}
@media (max-height: 639px) and (min-height: 519px) {
  .association-words {
    padding-right: 4px;
  }
}
@media (max-height: 519px) {
  .association-words {
    padding-right: 4px;
  }
}
</style>
