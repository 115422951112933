<template>
  <div class="bsc-mini-cart-loading">
    <div class="la-ball-pulse la-ball-pulse-2x">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<style lang="less">
.bsc-mini-cart-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #fff;
  .la-ball-pulse {
    font-size: 0;
    line-height: 137px;
  }

  .la-ball-pulse>div:nth-child(1) {
    animation-delay: -200ms;
  }

  .la-ball-pulse>div:nth-child(2) {
    animation-delay: -100ms;
  }

  .la-ball-pulse>div:nth-child(3) {
    animation-delay: 0ms;
  }

  @keyframes ball-pulse {
    0%,
    60%,
    100% {
      transform: scale(1);
      opacity: 1;
    }
    30% {
      transform: scale(.01);
      opacity: .1;
    }
  }
  .la-ball-pulse>div {
    background-color: #000;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 2px;
    vertical-align: middle;
    animation: ball-pulse 1s ease infinite;
  }
  .la-ball-pulse-2x div {
    width: 16px;
    height: 16px;
    margin: 0 4px;
  }
}
</style>
