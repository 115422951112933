<template>
  <div
    class="bs-c-swich__wrap"
    @mouseenter="mouseEnter"
    @mouseleave="isShowPop = false"
  >
    <a
      v-tap="{ id: 'click_bublle_language.comp_swich-lang-currency' }"
      href="javascript:;"
      aria-label="Currency"
      aria-expanded="false"
      role="listbox"
    >
      <span class="bs-c-swich__wrap-icon j-earth-icon">
        <SwichIcon />
      </span>
      <div class="j-switch-language-guide bs-c-swich__wrap-guide"></div>
    </a>

    <div
      v-if="isShowPop"
      class="bs-c-swich__wrap-dropdown bsc-header-dropdown"
    >
      <!-- 币种切换 -->
      <div class="bs-c-swich__dropdown-hd">
        {{ language.Currency }}
      </div>
      <div class="bs-c-swich__currency-list">
        <SSelect
          v-if="currency_infos.length"
          v-model="default_currency"
          v-tap="{ id: 'click_popup_country_change.comp_swich-lang-currency' }"
          @change="changeCurrency"
        >
          <SSelectOption
            v-for="item in currency_infos"
            :key="item.code"
            :value="item.code"
            :label="`${item.code} / ${item.symbol_left}${item.symbol_right}`"
          />
        </SSelect>
      </div>

      <!-- 语种切换 -->
      <div
        v-if="language_list.length > 0"
        class="bs-c-swich__dropdown-hd bs-c-swich__dropdown-langguage"
      >
        {{ language.Language }}
      </div>
      <div
        v-if="language_list.length > 0"
        class="bs-c-swich__lang-list"
      >
        <div
          v-for="(item, index) in language_list"
          :key="index"
          v-tap="{ id: 'click_change_site_language.comp_swich-lang-currency', data: { language: item.web_lang } }"
          class="bs-c-swich__lang-item"
          :class="{ 'bs-c-swich__lang-selected': item.web_lang == default_language }"
          @click="changeLanguage(item.web_lang)"
        >
          {{ item.title }}
        </div>
      </div>

      <!-- 跳转到国际站 -->
      <a
        v-if="!['www', 'hk'].includes(siteUid)"
        href="javascript:;"
        class="bs-c-swich__global-international"
        @click="toInternational"
      >
        {{ lang == 'tw' ? '國際站' : 'International Site' }}
      </a>
    </div>
  </div>
</template>

<script name="BSwichLangCurrency" setup lang="ts">
// 数据源：
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../common/dataSource'
import type { AS_SwichLangCurrency, DS_SwichLangCurrency, C_SwichLangCurrency } from '../../types'
const appConfigs = useAppConfigs()
const props = defineProps<{
  fsData?: DS_SwichLangCurrency.FS_DATA
  dataSource?: DS_SwichLangCurrency.DataSource
  analysisSource?: AS_SwichLangCurrency.AnalysisSource
}>()
const apis = getSource(props.dataSource, appConfigs)
import { ref, onBeforeMount, provide } from 'vue'
import SwichIcon from './icons/Swich.vue'
import { SSelect } from '@shein-aidc/sui-select/laptop'
import { SSelectOption } from '@shein-aidc/sui-select-option/laptop'
import { windowCacheDeleteCb, setLocalStorage, setCookie, parseQueryString, stringifyQueryString, extractQueryString } from '@shein/common-function'

const isShowPop = ref<Boolean>(false)
const default_language = ref<string>('')
const default_currency = ref<string>(appConfigs.$envs.currency)
const language_list = ref<C_SwichLangCurrency.LANG_LIST>([])
const currency_infos = ref<C_SwichLangCurrency.CURRENCY_LIST>([])
const siteUid = ref<string>(appConfigs.$envs.siteUID)
const lang = ref<string>(appConfigs.$envs.lang)
const language = ref<any>(appConfigs.$language)

onBeforeMount(async () => {
  // 获取语种列表
  const [langInfo, currencyInfo] = await Promise.all([
    apis.getlangs(),
    apis.getCurrencys(),
  ])
  language_list.value = langInfo?.info?.language_list || []
  currency_infos.value = currencyInfo?.info?.currency_infos || []
  const filterValue: any = language_list.value.find(_ => _.web_lang == appConfigs.$envs.lang) || {}
  default_language.value = filterValue?.web_lang || ''
})
// 数据源：END

// 刷新页面
const reloadPage = query => {
  // 清除缓存
  windowCacheDeleteCb({
    key: 'dynamicData',
  })
  location.href = `${location.pathname}?${stringifyQueryString({ queryObj: query })}`
}

// 语种切换
const changeLanguage = async code => {
  const res = await apis.setlang(code)
  if (res.code == '0') {
    const query: any = parseQueryString(extractQueryString(location.href)) || {}
    let searchParams = { ...query, ...{ _t: Date.now() } }
    if(searchParams?.lang != '0' && language_list.value.some(_ => _.web_lang == searchParams.lang)) {
      searchParams.lang = '0'
    }
    reloadPage(searchParams)
  }
}

// 币种切换
const changeCurrency = async data => {
  if (data.value == appConfigs.$envs.currency) return
  const res = await apis.setCurrency(data.value)
  if (res.code == '0') {
    // 设置缓存
    const expireTime: number = 3600 * 24 * 30 // 30 day
    setLocalStorage({ key: 'manualChangeCurrency', value: 1, expire: expireTime * 1000 }) // 手动切换

    const querys: any = parseQueryString(location.search)
    delete querys.currency
    querys._t = Date.now()
    reloadPage(querys)
  }
}

// 跳转到国际站
const toInternational = () => {
  setCookie({ key: `jump_to_${siteUid.value}`, value: '0', end: 3600 * 24, curMainDomain: true }) //1天内不再跳转。
  window.location.href = `https://www.shein.com?is_manual_change_site=0&is_from_origin_btn=0&ref=${siteUid.value}&ret=www&from_country=${siteUid.value}`
}

const mouseEnter = () => {
  isShowPop.value = true
  triggerNotice({
    id: 'click_bublle_language.comp_swich-lang-currency',
  })
}

// 埋点源：
import { useAnalysis as useSdkAnalysis } from '../../common/analysisSource'
const analysisInstance = useSdkAnalysis(props.analysisSource)
const { vTap, triggerNotice } = analysisInstance

provide('analysisInstance', analysisInstance)
// 埋点源：END
</script>

<style lang="less">
.bs-c-swich {
  &__wrap {
    font-size: 14px;
    line-height: 1.15;
    position: relative;
    vertical-align: middle;
    display: inline-block;
  }
  &__wrap-dropdown {
    position: absolute;
    right: -4px;
    background: #fff;
    z-index: 600;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, .08);
    transition: transform .3s;
    width: 270px;
    padding: 20px;
    white-space: nowrap;
  }
  &__wrap-guide {
    .c-language-guide {
      bottom: -32px;
      right: -15px;
      z-index: 99;
    }
  }
  &__dropdown-hd {
    padding-bottom: 8px;
    margin-bottom: 8px;
    font-weight: 700;
    margin-top: 25px;
  }
  &__dropdown-langguage {
    border-bottom: 1px solid rgba(0,0,0,.1);
  }
  &__lang-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
  }
  &__currency-list {
    height: 34px;
    .sui-select {
      height: 34px;
    }
  }
  &__lang-item {
    font-size: 12px;
    min-width: 30%;
    margin-bottom: 20px;
    color: #767676;
    cursor: pointer;
  }
  &__lang-selected {
    color: rgba(0,0,0,.8);
    font-weight: 700;
  }
  &__global-international {
    margin-top: 20px;
    display: block;
    color: #2d68a8;
    text-decoration: none;
    text-align: center;
  }
}
</style>
