<template>
  <div class="bsc-mini-cart-footer">
    <slot name="top"></slot>
    <div class="bsc-mini-cart-footer__content">
      <div
        v-if="originalPrice"
        class="bsc-mini-cart-original-price"
      >
        {{ originalPrice.description }}:{{ originalPrice.price.amountWithSymbol }}
      </div>
      <div
        v-if="savedTip"
        class="bsc-mini-cart-saved"
      >
        <SPopover
          v-bind="popoverProps"
          :trigger="showTipsEndExpend ? (test ? 'click' : 'hover') : 'unset'"
          @before-open="onBeforeOpen"
          @closed="onClosed"
        >
          <PromotionDetail
            v-if="showPromotionDetailPopUp"
            :promotion-detail="promotionDetailPopUp"
            :css-right="cssRight"
            :cart-item-map="cartItemMap"
            :products="products"
            @modify-cart-check-status="hanleModifyCartCheckStatus"
            @on-fold="onFold"
          />
          <template #reference>
            <div class="bsc-mini-cart-saved__content j-bsc-mini-cart-saved__content">
              <span class="bsc-mini-cart-saved__text">{{ savedTip }}</span>
              <Icon
                v-if="showTipsEndExpend"
                :class="[
                  'bsc-mini-cart-saved__icon',
                  {
                    'bsc-mini-cart-saved__icon_rotate': showPromotionDetailPopUp
                  }
                ]"
                name="sui_icon_more_down_12px"
                size="12px"
              />
            </div>
          </template>
        </SPopover>
      </div>
      <div class="bsc-mini-cart-footer__bottom">
        <SCheckbox
          label="all"
          :model-value="isCheckAll"
          :disabled="!validMallCarts.length"
          gap="12px"
          size="size16"
          outside-check
          @change="onChange"
        >
          {{ language.SHEIN_KEY_PC_14484 }}
        </SCheckbox>
        <div class="bsc-mini-cart-total-price">
          <span class="bsc-mini-cart-total-price__label">{{ language.SHEIN_KEY_PC_14540 }}:</span>
          <SPopover
            v-bind="popoverProps"
            :trigger="showPriceEndExpend ? (test ? 'click' : 'hover') : 'unset'"
            @before-open="onBeforeOpen"
            @closed="onClosed"
          >
            <PromotionDetail
              v-if="showPromotionDetailPopUp"
              :promotion-detail="promotionDetailPopUp"
              :css-right="cssRight"
              :cart-item-map="cartItemMap"
              :products="products"
              @modify-cart-check-status="hanleModifyCartCheckStatus"
            />
            <template #reference>
              <div
                class="bsc-mini-cart-total-price__content j-bsc-mini-cart-total-price__content"
                :style="{ color: totalPrice.color }"
              >
                <CrossedPrice
                  v-if="crossedPrice"
                  :crossed-price="crossedPrice"
                />
                <span class="bsc-mini-cart-total-price__value">
                  {{ totalPrice.price.amountWithSymbol }}
                </span>
                <Icon
                  v-if="showPriceEndExpend"
                  :class="[
                    'bsc-mini-cart-total-price__icon',
                    {
                      'bsc-mini-cart-total-price__icon_rotate': showPromotionDetailPopUp
                    }
                  ]"
                  name="sui_icon_more_down_12px"
                  size="12px"
                />
              </div>
            </template>
          </SPopover>
        </div>
      </div>
      <div
        v-if="lang == 'au' || lang == 'nz'"
        class="bsc-mini-cart-gst-tip"
      >
        Incl GST
      </div>
      <SButton
        :type="['H44PX', 'primary']"
        role="button"
        class="bsc-mini-cart-footer__button"
        @click="onClickButton"
      >
        {{ language.SHEIN_KEY_PC_15743 }} <span v-show="checkedQuantity">({{ checkedQuantity }})</span>
      </SButton>
    </div>
  </div>
</template>

<script name="BMiniCartFooter" setup lang="ts">
import { ref, computed, watch, inject } from 'vue'
import { cloneDeep } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'
import { SButton } from '@shein-aidc/sui-button/laptop'
import { SPopover } from '@shein-aidc/sui-popover/laptop'
import { SCheckbox } from '@shein-aidc/sui-checkbox/laptop'
import usePromotionDetail from '../hooks/usePromotionDetail'
import CrossedPrice from './crossed-price.vue'
import PromotionDetail from './promotion-detail.vue'
import type { AS_MiniCart } from '../../../types'

const props = defineProps({
  cartInfo: {
    type: Object,
    required: true,
  },
  validMallCarts: {
    type: Array,
    required: true,
  },
  cartItemMap: {
    type: Object,
    required: true,
  },
  lang: {
    type: String,
    required: true,
  },
  cssRight: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    required: true,
  },
  topOffsetTarget: {
    type: String,
  },
})

const modifyCartCheckStatus = inject('modifyCartCheckStatus') as Function
const analysisInstance = inject<AS_MiniCart.AnalysisInstance>('analysisInstance')
const onBeforeClickModifyCheckbox = inject('onBeforeClickModifyCheckbox') as Function
const onAfterClickModifyCheckbox = inject('onAfterClickModifyCheckbox') as Function
const onClickViewCart = inject('onClickViewCart') as Function
const test = inject('test') as boolean

// 整车价格模块
const mallCartPriceData = computed(() => {
  return props.cartInfo?.mallCartPriceData || {}
})
// 划线价
const crossedPrice = computed(() => {
  return mallCartPriceData.value?.crossedPrice || null
})
// 到手价
const estimatedPrice = computed(() => {
  return mallCartPriceData.value?.estimatedPrice || null
})
// 原价
const originalPrice = computed(() => {
  return mallCartPriceData.value?.originalPrice || null
})
// 节省金额
const savedPrice = computed(() => {
  return mallCartPriceData.value?.savedPrice || null
})
// 总价
const totalPrice = computed(() => {
  return mallCartPriceData.value?.totalPrice || null
})
const savedTip = computed(() => {
  if (mallCartPriceData.value.priceMode !== 'normal') {
    return estimatedPrice.value ? `${estimatedPrice.value.description} ${estimatedPrice.value.price.amountWithSymbol}` : ''
  }
  return savedPrice.value ? `${savedPrice.value.description} ${savedPrice.value.price.amountWithSymbol}` : ''
})

/**
 * 优惠提示右侧是否展示整车优惠明细箭头
 */
 const showTipsEndExpend = computed(() => {
  return !!savedTip.value && props.cartInfo.showPromotionDetailPopUp == '1'
})
/**
 * 总价右侧是否展示整车优惠明细箭头
 */
const showPriceEndExpend = computed(() => {
  return !showTipsEndExpend.value && props.cartInfo.showPromotionDetailPopUp == '1'
})

watch(() => props.cartInfo.showPromotionDetailPopUp, (val) => {
  if (val == '1') {
    analysisInstance?.triggerNotice({
      id: 'expose_promotiondetails.comp_mini-cart',
    })
  }
}, {
  immediate: true,
})

const el = computed(() => {
  if (showTipsEndExpend.value) return '.j-bsc-mini-cart-saved__content'
  if (showPriceEndExpend.value) return '.j-bsc-mini-cart-total-price__content'
  return ''
})
const { promotionDetailMaxHeight, promotionDetailPlacemen, updatePromotionDetailStyles } = usePromotionDetail({ el: el.value, topOffsetTarget: props.topOffsetTarget })
const popoverProps = computed(() => {
  return {
    placemen: promotionDetailPlacemen.value,
    propStyle: {
      width: '360px',
      maxWidth: 'unset',
      right: props.cssRight ? 'auto' : 0,
      left: props.cssRight ? 0 : 'auto',
    },
    delayRender: 300,
    appendToBody: false,
    fixDisappearPosition: true,
  }
})
const promotionDetailPopUp = computed(() => {
  return props.cartInfo.promotionDetailPopUp || {}
})

const checkedQuantity = computed(() => {
  return props.cartInfo?.checkedCartSumQuantity
})

const isCheckAll = computed(() => {
  return props.validMallCarts.length && (props.validMallCarts as any[]).every(cart => cart.is_checked == '1')
})

const products = ref<any[]>([])
// 整车优惠明细是否显示
const showPromotionDetailPopUp = ref(false)
const hasFlashSale = computed(() => {
  const aClassPromotions = promotionDetailPopUp.value?.items?.find(item => item.benefitType === 'aClassPromotions')
  const bClassPromotions = promotionDetailPopUp.value?.items?.find(item => item.benefitType === 'bClassPromotions')
  return aClassPromotions?.data?.twoLevelData?.some(item => item.typeId == '10' && !!item.questionMark) || bClassPromotions?.data?.twoLevelData?.some(item => item.typeId == '10' && !!item.questionMark)
})
const onBeforeOpen = () => {
  updatePromotionDetailStyles()
  products.value = cloneDeep(promotionDetailPopUp.value.items?.find(item => item.type == '1')?.products || [])
  showPromotionDetailPopUp.value = true
  analysisInstance?.triggerNotice({
    id: 'expose_promotiondetailsbox.comp_mini-cart',
  })
  analysisInstance?.triggerNotice({
    id: 'click_promotiondetails.comp_mini-cart',
    data: {
      is_open: 1,
    },
  })
  if(hasFlashSale.value){
    analysisInstance?.triggerNotice({
      id: 'expose_promotiondetails_original_price.comp_mini-cart',
    })
  }
}
const onClosed = () => {
  showPromotionDetailPopUp.value = false
  analysisInstance?.triggerNotice({
    id: 'expose_promotiondetailsbox.comp_mini-cart',
  })
  analysisInstance?.triggerNotice({
    id: 'click_promotiondetails.comp_mini-cart',
    data: {
      is_open: 1,
    },
  })
}
const onChange = ({ checked = false }) => {
  onBeforeClickModifyCheckbox(checked ? 1 : 0, 'all')
  let reqParam = {
    operation_type: checked ? 3 : 4,
    cart_id_list: [],
    append_id_list: [],
  }
  analysisInstance?.triggerNotice({
    id: 'click_all_product.comp_mini-cart',
    data: {
      is_select: checked ? 0 : 1,
    },
  })
  modifyCartCheckStatus(reqParam).then((res) => {
    onAfterClickModifyCheckbox(checked ? 1 : 0, 'all', res?.code == 0 ? 1 : 0)
  })
}
let jumping = false
const onClickButton = () => {
  onClickViewCart()
  if (jumping) return
  jumping = true
  window.location.href = '/cart'
}
// 整车优惠明细中更改勾选状态
const hanleModifyCartCheckStatus = ({ info, checked, callback }) => {
  analysisInstance?.triggerNotice({
    id: 'click_select_someproduct.comp_mini-cart',
    data: {
      select_type: 1,
      is_select: checked ? 1 : 0,
      position: 'popup',
    },
  })
  onBeforeClickModifyCheckbox(checked ? 1 : 0, 'item')
  const reqParam = {
    operation_type: checked ? 1 : 2,
    cart_id_list: [info.id],
    append_id_list: info.appendIds || [],
  }
  modifyCartCheckStatus(reqParam).then((res) => {
    onAfterClickModifyCheckbox(checked ? 1 : 0, 'item', res?.code == 0 ? 1 : 0)
    callback && callback(res)
  }).catch(() => {
    callback && callback()
  })
}
const onFold = ({ isOpen, type }) => {
  analysisInstance?.triggerNotice({
    id: 'click_promotiondetails_detail.comp_mini-cart',
    data: {
      is_open: isOpen,
      type,
    },
  })
}
</script>

<style lang="less">
.bsc-mini-cart-footer {
  .bsc-mini-cart-footer__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    background: #fff;
    .bsc-mini-cart-promotion-detail {
      max-height: v-bind(promotionDetailMaxHeight);
    }
  }
  .bsc-mini-cart-original-price,
  .bsc-mini-cart-saved,
  .bsc-mini-cart-gst-tip {
    margin-bottom: 6px;
    width: 100%;
    text-align: right;
    font-size: 14px;
    color: #222;
  }
  .bsc-mini-cart-gst-tip {
    margin-top: -6px;
  }

  .bsc-mini-cart-saved__content {
    display: flex;
    align-items: center;
    color: #C44A01;
    cursor: pointer;
  }
  .bsc-mini-cart-saved__text {
    margin-right: 4px;
  }
  .bsc-mini-cart-saved__icon,
  .bsc-mini-cart-total-price__icon {
    transition: .2s;
    &.bsc-mini-cart-saved__icon_rotate {
      transform: rotate(180deg);
    }
    &.bsc-mini-cart-total-price__icon_rotate {
      transform: rotate(180deg);
    }
  }

  .bsc-mini-cart-footer__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    width: 100%;
    .sui-checkbox__label-text {
      padding-left: 8px;
    }
  }

  .bsc-mini-cart-total-price {
    min-width: 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    .sui-popover__trigger {
      min-width: 0;
    }
  }
  .bsc-mini-cart-total-price__label {
    color: #222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .bsc-mini-cart-total-price__content {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .bsc-mini-cart-total-price__value {
    flex-shrink: 1;
    margin-left: 2px;
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .bsc-mini-cart-footer__button {
    width: 100%;
    text-transform: uppercase;
  }
}
</style>
