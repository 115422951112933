<template>
  <div
    :class="[
      'bsc-mini-cart-full-promotions',
    ]"
  >
    <template
      v-for="(item) in promotions"
      :key="item.data && (item.data.promotion_id || item.data.type_id)"
    >
      <div
        v-if="item.data && [21, 26, 14, 4, 22].includes(+item.data.type_id)"
        class="bsc-mini-cart-full-promotion-cell bsc-mini-cart-full-promotion-item"
        @click="toCartAndScroll(item.data)"
      >
        <div class="bsc-mini-cart-full-promotion-cell__content">
          <div
            class="bsc-mini-cart-full-promotion-cell__title"
          >
            <span
              v-if="isShowNewUserTag(item)"
              class="bsc-mini-cart-full-promotion-cell__title-tag"
            >
              {{ item.data.newUserReturnCouponTips }}
            </span>
            <span
              class="bsc-mini-cart-full-promotion-cell__title-text"
              v-html="item.promotionTips && item.promotionTips.html_text || ''"
            ></span>
          </div>
          <Icon
            name="sui_icon_more_right_12px_1"
            size="12px"
            :is-rotate="appConfigs.$envs.cssRight"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script name="BMiniCartFullPromotions" setup lang="ts">
import { computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const appConfigs = useAppConfigs()
const props = defineProps({
  promotionData: {
    type: Array,
    default: (() => []),
  },
  language: {
    type: Object,
    default: (() => {}),
  },
  showNewUserTag: {
    type: Boolean,
    default: false,
  },
})

const promotions = computed(() => {
  let promotionList: any[] = []
  ;(props.promotionData as any[])?.forEach(item => {
    item?.contentData?.forEach(content => {
      promotionList.push(content.groupHeadInfo)
    })
  })
  return promotionList
})

function toCartAndScroll(item: { type_id: string, promotion_id: string }) {
  if([21, 26, 14].includes(+item.type_id)){
    window.location.href = `/cart?promotionId=${item.promotion_id}`
  } else if([4, 22].includes(+item.type_id)) {
    window.location.href = '/cart'
  }
}

// 是否展示新客返券标签
const isShowNewUserTag = (item) => {
  return props.showNewUserTag && item.data?.newUserReturnCouponTips
}
</script>

<style lang="less">
.bsc-mini-cart-full-promotions_hide-more>div:not(:first-child):not(.bsc-mini-cart-full-promotions__more) {
  display: none;
}
.bsc-mini-cart-full-promotion-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  line-height: 14px;
  background: #FFF6F3;
  color: #222;
  cursor: pointer;
  .bsc-mini-cart-full-promotion-cell__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: normal;
    width: 100%;
  }
  .bsc-mini-cart-full-promotion-cell__title {
    padding-right: 10px;
    flex: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    em {
      color: #C44A01;
    }
    .bsc-mini-cart-full-promotion-cell__title-tag {
      display: inline-block;
      padding: 0 4px;
      margin-right: 4px;
      max-width: 86px;
      border-radius: 2px;
      background: linear-gradient(93deg, #FF858F -10.15%, #FF6A76 -10.14%, #FF3351 33.79%, #FF3351 75.48%, #EF2649 113.06%), linear-gradient(90deg, #FF858F 0%, #FF3351 100%), linear-gradient(57deg, #FF63C2 -35.79%, #FF4142 41.52%, #FFFC33 108.14%);
      font-size: 10px;
      color: #ffffff;
      line-height: 14px;
      vertical-align: text-bottom;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .bsc-mini-cart-full-promotion-cell__process {
    width: 100%;
    height: 3px;
    background-color: #bbbbbb;
    border-radius: 2px;
    margin-top: 4px;
  }
  .bsc-mini-cart-full-promotion-cell__process-inner {
    height: 3px;
    background-color: #222222;
    border-radius: 2px;
    transition: width .5s linear;
  }
}
.bsc-mini-cart-full-promotions__more {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  .bsc-mini-cart-full-promotions__more-content {
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 12px;
    background: #f2f2f2;
    display: flex;
    gap: 2px;
    justify-content: center;
    align-items: center;
    span {
      color: #FA6338;
    }
  }
}
.bsc-mini-cart-full-promotion-item {
  margin: 10px 20px 0;
  border-bottom: none;
  + .bsc-mini-cart-full-promotion-item {
    margin-top: 5px;
  }
}
</style>
