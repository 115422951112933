<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.1609 2.83334L8.33733 1.75988L7.66329 1.02119L6.00525 2.53411L4.39985 1.0261L3.7152 1.75497L4.86322 2.83334H1.16699V5.83334H1.83366V10.8333H10.167V5.83334H10.8337V2.83334H7.1609ZM9.16699 5.83334H2.83366V9.83334H9.16699V5.83334ZM2.16699 4.83334V3.83334H9.83366V4.83334H2.16699Z"
      fill="white"
    />
  </svg>
</template>
