export function getClonedDomWidth(targetElement) {
  targetElement.style.position = 'fixed'
  targetElement.style.zIndex = '-9999'
  targetElement.style.top = '-9999px'
  document.body.appendChild(targetElement)
  const width = targetElement.offsetWidth
  document.body.removeChild(targetElement)
  return width
}

export function getCloneDomWithText(targetElement, textMap = {}) {
  if (!targetElement) return

  const clonedNode = targetElement.cloneNode(true) // 深度克隆

  const replaceText = (node) => {
    if (node.nodeType === Node.TEXT_NODE) { // 处理文本节点
      for (const [key, value] of Object.entries(textMap)) {
        if (node.textContent.includes(key)) {
          node.textContent = node.textContent.replace(key, value)
        }
      }
    } else if (node.childNodes) { // 递归处理子节点
      node.childNodes.forEach(replaceText)
    }
  }

  replaceText(clonedNode)
  return clonedNode
}
