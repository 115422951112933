<template>
  <img
    v-if="medalUrl"
    :src="medalUrl"
    :alt="alertText"
    :style="{height, cursor: 'pointer'}"
    @click="jumpToVip"
  />
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: 'M',
  },
  alertText: {
    type: String,
    default: '',
  },
  cssRight: {
    type: Boolean,
    default: false,
  },
  host: {
    type: String,
    default: '',
  },
  medalUrl: {
    type: String,
    default: '',
  },
})
const height = computed(() => {
  const sizeMap = {
    S: '18px',
    M: '22px',
    L: '26px',
  }
  return sizeMap[props.size] ? sizeMap[props.size] : sizeMap.M
})

const jumpToVip = () => {
  window.location.href = `${props.host}/user/sheinvip`
}
</script>
