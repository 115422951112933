import * as standard from './sources/standard'
import {
  organizeDataSource,
  createFsDataRunnerBySource,
} from '@shein-aidc/utils-data-source-toolkit'
import type { Libs } from '@shein-aidc/bs-sdk-libs-manager'
import type { DS_Category } from '../../types'

export function getSource(
  dataSource: DS_Category.DataSource = {},
  appConfigs: Libs.AppConfigs,
): DS_Category.APIS {
  return organizeDataSource<DS_Category.APIS, DS_Category.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(dataSource: DS_Category.DataSource = {}, appConfigs: Libs.AppConfigs) {
  const { getLanguage } = getSource(dataSource, appConfigs)
  return createFsDataRunnerBySource<DS_Category.FS_APIS, DS_Category.FS_DATA>({
    getLanguage
  })
}
