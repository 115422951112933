<template>
  <a
    v-tap="{
      id: 'click_top_site_collect.comp_wish-list-entry',
      data: {
        is_red: redpoint,
        collect_count: collectCount,
        $options: { send_beacon: 1 }
      },
    }"
    v-expose="{
      id: 'expose_top_site_collect.comp_wish-list-entry',
      prefix: 'comp_wish-list-entry',
      data: {
        is_red: redpoint,
        collect_count: collectCount,
      },
    }"
    class="bsc-wish-list-entry_wrap"
    tabindex="0"
    role="link"
    :aria-label="ariaLabel+''"
    href="javascript:;"
    @click="goWishlist"
  >
    <span
      style="vertical-align: middle; display: inline-block; line-height: 0"
      :style="{
        'font-size': props.size,
      }"
    >
      <svg
        style="width: auto; height: 1em;"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.4101 4.63682C18.2271 2.45439 14.6885 2.45439 12.5054 4.63682L12 5.14225L11.4946 4.63682L11.3096 4.46001C9.11597 2.45608 5.71228 2.51502 3.58986 4.63682C1.4068 6.81988 1.53425 9.92463 3.58986 12.5415C3.79686 12.8051 4.04745 13.1008 4.33075 13.4196L4.77888 13.9139L5.26793 14.4359L5.78973 14.9788L6.61599 15.8171L7.02543 16.2251L7.80678 16.993L8.9559 18.1021L10.489 19.5521L12 20.9517L13.8221 19.2603L15.0441 18.1021L16.1932 16.993L17.384 15.8171L18.2103 14.9788L18.7321 14.4359L19.2211 13.9139L19.6693 13.4196C19.9526 13.1008 20.2031 12.8051 20.4101 12.5415C22.4657 9.92463 22.5932 6.81988 20.4101 4.63682ZM14.0731 5.90624C15.4827 4.65137 17.6441 4.69965 18.9961 6.05124C20.3711 7.42618 20.3484 9.38241 18.8373 11.3061C18.7632 11.4005 18.6812 11.5013 18.5917 11.6084L18.3008 11.9478L17.9664 12.3226L17.5901 12.7313L16.951 13.4041L16.4777 13.8901L15.9685 14.4042L15.3453 15.0235L14.3761 15.9698C13.6202 16.7012 12.8338 17.4474 12.0472 18.1828L12 18.226L11.1674 17.4447C10.3843 16.705 9.61144 15.963 8.8792 15.2443L8.02772 14.4004L7.51985 13.8877L6.82513 13.171L6.21623 12.5225L5.86091 12.1307L5.54814 11.7735L5.2797 11.4525L5.16265 11.3061C3.65158 9.38241 3.62893 7.42618 5.00407 6.05104C6.40592 4.64959 8.67851 4.64959 10.0806 6.05124L12 7.97067L13.9196 6.05104L14.0731 5.90624Z"
          fill="rgb(102, 102, 102)" />
      </svg>
    </span>
    <em
      class="bsc-wish-list-entry_point"
      :style="{ display: displayRedPoint ? 'block' : 'none' }"
    ></em>
    <code class="bsc-wish-list-entry_num">{{ collectCount }}</code>
  </a>
</template>
<script name="BWishListEntry" setup lang="ts">
import { defineProps, onMounted, defineExpose, withDefaults, computed, onUnmounted } from 'vue'
import { getSource } from '../../common/dataSource'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { useCommon, useReportCommon } from '../../hooks/common'
import { injectCommonHeaderEventProxy } from '@shein-aidc/bs-common-header-laptop'

interface PropsType {
  color?: string,
  size?: string,
  ariaLabel?: string,
  analysisSource?: object,
}
const props = withDefaults(defineProps<PropsType>(), {
  size: '24px',
  ariaLabel: '',
  analysisSource: () => ({}),
})

// 全局配置
const appConfigs = useAppConfigs()
const apis = getSource({}, appConfigs) // 数据源

const {
  redpoint,
  ariaLabel,
  showRedpoint,
  collectCount,
  fetchCollectCount: reAddwish,
  updateRedPointStatus,
  manualUpdateCollectCount: updateCount,
} = useCommon({ appConfigs, props, apis })

const {
  vTap,
  vExpose,
  resetExpose,
} = useReportCommon({ props, redpoint })

const displayRedPoint = computed(() => {
  return showRedpoint.value && /1|2/.test(String(redpoint.value))
})

const CommonHeaderEventProxyEventProxy = injectCommonHeaderEventProxy()
onMounted( () => {
  updateRedPointStatus()
  reAddwish()
    CommonHeaderEventProxyEventProxy?.subscribe('wishListUpdataStatus', {
      isLogin() {
        updateRedPointStatus(true)
        reAddwish(true)
      },
    })
})

onUnmounted(() => {
  CommonHeaderEventProxyEventProxy?.describe('wishListUpdataStatus')
})

const goWishlist = () => {
  location.href = '/user/wishlist?pf=topbar'
}

defineExpose({
  collectCount,
  reAddwish,
  resetExpose,
  updateCount,
  updateRedPointStatus,
})
</script>

<style lang="less">
.bsc-wish-list-entry_wrap {
  position: relative;
  text-decoration: none;
  &:hover,&:active {
    text-decoration: none;
  }
}
.bsc-wish-list-entry_point {
  background: #fe3b30;
  border-radius: 50%;
  position: absolute;
  height: 6px;
  width: 6px;
  top: -3px;
  left: 17px;
}
.bsc-wish-list-entry_num {
  font-size: 14px;
  font-weight: 700;
  vertical-align: middle;
  line-height: 1;
  font-family: Muli,Arial,Helvetica,sans-serif!important;
}
</style>
