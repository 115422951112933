<template>
  <swiper-container
    v-if="show"
    ref="miniCartSwiperContainerRef"
    init="false"
    class="bsc-mini-cart-swiper"
  >
    <!-- eslint-disable vue/no-deprecated-slot-attribute -->
    <div slot="container-start">
      <slot name="button-prev">
        <div
          ref="miniCartSwiperButtonPrevRef"
          class="swiper-button-prev swiper-button-disabled j-r-prev"
          tabindex="0"
          aria-label="prev page"
        >
          <Icon
            name="sui_icon_more_left_18px"
            :size="uiConfig.iconSize || '18px'"
            :is-rotate="cssRight"
          />
        </div>
      </slot>
    </div>
    <swiper-slide
      v-for="(item, index) in items"
      :key="index"
    >
      <slot
        :item="item"
        :index="index"
      ></slot>
    </swiper-slide>
    <div slot="container-end">
      <slot name="button-next">
        <div
          ref="miniCartSwiperButtonNextRef"
          class="swiper-button-next j-r-next"
          tabindex="0"
          aria-label="next page"
        >
          <Icon
            name="sui_icon_more_right_18px"
            :size="uiConfig.iconSize || '18px'"
            :is-rotate="cssRight"
          />
        </div>
      </slot>
    </div>
  </swiper-container>
</template>

<script name="BMiniCartSwiper" setup lang="ts">
import { ref, watch, nextTick } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { register } from 'swiper/element'
import { Navigation } from 'swiper/modules'
typeof window !== 'undefined' && register()

const props = defineProps({
  items: {
    type: Array,
    default(){
      return []
    },
  },
  swiperConfig: {
    type: Object,
    default(){
      return {}
    },
  },
  uiConfig: {
    type: Object,
    default(){
      return {}
    },
  },
  cssRight: {
    type: Boolean,
    default: false,
  },
})

const show = ref(true)
const swiper = ref<any>(null)

const miniCartSwiperContainerRef = ref<any>(null)
const miniCartSwiperButtonPrevRef = ref(null)
const miniCartSwiperButtonNextRef = ref(null)
const initSwiper = () => {
  if (!miniCartSwiperContainerRef.value) return

  let swiperConfig = {
    modules: [Navigation],
    navigation: {
      prevEl: miniCartSwiperButtonPrevRef.value,
      nextEl: miniCartSwiperButtonNextRef.value,
    },
    direction: 'horizontal',
    slidesPerView: 'auto',
    slidesPerGroup: 6,
    spaceBetween: 5,
    roundLengths: false,
    observer: true,
    observeParents: true,
    ...props.swiperConfig,
  }
  Object.assign(miniCartSwiperContainerRef.value, swiperConfig)
  miniCartSwiperContainerRef.value?.initialize?.()
  swiper.value = miniCartSwiperContainerRef.value?.swiper
}
watch(() => props.items, (val) => {
  if(typeof window === 'undefined') return
  if(!val.length) return
  if(swiper.value) {
    swiper.value.destroy?.()
    show.value = false
  }
  swiper.value = null
  nextTick(() => {
    show.value = true
    nextTick(() => {
      initSwiper()
    })
  })
}, { immediate: true })
</script>

<style lang="less">
.bsc-mini-cart-swiper {
  swiper-slide {
    width: max-content;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position:absolute;
    top: 50%;
    margin-top: -8px;
    width: 16px;
    height: 16px;
    background: rgba(255,255,255,.94);
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    vertical-align: unset;

    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: normal;

    pointer-events: auto !important; /* stylelint-disable-line declaration-no-important */
    cursor: pointer;
    color: #222;
    z-index: 10;
  }
  .swiper-button-prev {
    margin-left: 5px;
  }
  .swiper-button-next {
    margin-right: 5px;
  }

  .swiper-button-next.swiper-button-disabled,.swiper-button-prev.swiper-button-disabled{opacity:.35;cursor:auto;pointer-events:none}.swiper-button-next.swiper-button-hidden,.swiper-button-prev.swiper-button-hidden{opacity:0;cursor:auto;pointer-events:none}.swiper-navigation-disabled .swiper-button-next,.swiper-navigation-disabled .swiper-button-prev{display:none!important}.swiper-button-next svg,.swiper-button-prev svg{width:100%;height:100%;object-fit:contain;transform-origin:center}.swiper-rtl .swiper-button-next svg,.swiper-rtl .swiper-button-prev svg{transform:rotate(180deg)}.swiper-button-prev,.swiper-rtl .swiper-button-next{left:0;right:auto}.swiper-button-next,.swiper-rtl .swiper-button-prev{right:0;left:auto}.swiper-button-lock{display:none}
}
</style>
