<template>
  <div class="bsc-mini-cart-promotion-detail">
    <header
      v-if="promotionDetail.title"
      class="bsc-mini-cart-promotion-detail__header"
    >
      {{ promotionDetail.title }}
    </header>
    <section
      v-if="promotionDetail.items"
      class="bsc-mini-cart-promotion-detail__body"
    >
      <template
        v-for="(item, i) in promotionDetail.items"
        :key="i"
      >
        <MiniCartSwiper
          v-if="item.type == '1'"
          :class="[
            'bsc-mini-cart-promotion-detail__products',
            {
              'bsc-mini-cart-promotion-detail__products_no-navigation': !isShowNavigation
            }
          ]"
          :items="products || []"
          :swiper-config="swiperConfig"
          :ui-config="{
            iconSize: '12px'
          }"
          v-bind="$attrs"
        >
          <template #default="slotProps">
            <PromotionDetailProduct
              :info="(slotProps as any).item"
              :cart-item-map="cartItemMap"
              v-bind="$attrs"
            />
          </template>
        </MiniCartSwiper>
        <component
          :is="CompMap[item.type]"
          :data="item.data"
          :left-tip-bold="true"
          @fold="(isOpen: boolean) => onFold(isOpen, item.benefitType)"
        >
          <template #item-top="{ data }">
            <div class="bsc-mini-cart-promotion-detail__cart-list">
              <PromotionDetailProduct
                v-for="info in data.products"
                :key="info.id"
                :info="info"
                :is-show-checkbox="false"
                :is-show-belt="false"
                width="30px"
                height="30px"
              />
            </div>
          </template>
        </component>
      </template>
    </section>
  </div>
</template>

<script name="BMiniCartPromotionDetail" setup lang="ts">
import { ref } from 'vue'
import { DiscountCell, DiscountDropdown } from '@shein-aidc/bs-cart-item-laptop'
import MiniCartSwiper from './mini-cart-swiper.vue'
import PromotionDetailProduct from './promotion-detail-product.vue'

const CompMap = {
  2: DiscountCell,
  3: DiscountDropdown,
}

defineProps({
  promotionDetail: {
    type: Object,
    required: true,
  },
  products: {
    type: Array,
    default: () => [],
  },
  cartItemMap: {
    type: Object,
    default: () => ({}),
  },
})
const emit = defineEmits(['onFold'])

const isShowNavigation = ref(false)
const handleSwiperInit = (swiper) => {
  const { allowSlideNextm, allowSlidePrev } = swiper
  isShowNavigation.value = allowSlideNextm || allowSlidePrev
}
const swiperConfig = {
  slidesPerGroup: 3,
  spaceBetween: 0,
  on: {
    init: handleSwiperInit,
  },
}

const onFold = (isOpen: boolean, benefitType: string) => {
  let type = ''
  if (benefitType === 'aClassPromotions' || benefitType === 'bClassPromotions') {
    type = 'promotion'
  }
  if (benefitType === 'coupon') {
    type = 'coupon'
  }
  emit('onFold', { isOpen, type })
}
</script>

<style lang="less">
.bsc-mini-cart-promotion-detail {
  display: flex;
  flex-direction: column;
  .bsc-mini-cart-promotion-detail__header {
    font-size: 16px;
    font-weight: 700;
    color: #222;
    margin-bottom: 12px;
  }
  .bsc-mini-cart-promotion-detail__body {
    padding-right: 12px;
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none; // 火狐
    -ms-overflow-style: none; // ie
    &::-webkit-scrollbar {
      display: none;
    }

    .bsc-mini-cart-promotion-detail__products {
      position: relative;
      display: flex;
      margin-right: -12px;
      margin-bottom: 12px;
      swiper-slide {
        padding-right: 5px;
      }
      swiper-slide:last-of-type {
        padding-right: 0;
      }
    }
    .bsc-mini-cart-promotion-detail__products_no-navigation {
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
    }
    .bsc-mini-cart-promotion-detail__cart-list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 6px;
    }
  }
}
</style>
