import { ref } from 'vue'

interface IOptions {
  el: string
  topOffsetTarget?: string
}
export default function usePromotionDetail(options: IOptions) {
  const { el, topOffsetTarget } = options

  const promotionDetailMaxHeight = ref('62vh')
  const promotionDetailPlacemen = ref('top-end')

  const updatePromotionDetailStyles = () => {
    if (!el) return
    const elDom = document.querySelector(el)
    if (!elDom) return
    const topOffsetTargetDom = topOffsetTarget ? document.querySelector(topOffsetTarget) : null
    const { bottom: topOffsetTargeBottom } = topOffsetTargetDom?.getBoundingClientRect() || {}
    const diff = 50 + (topOffsetTargeBottom || 0)
    const { top, bottom } = elDom.getBoundingClientRect()
    const clientHeight = document.body.clientHeight
    let maxHeight = clientHeight / 2
    const diffBottom = clientHeight - bottom
    console.log('top', top, diffBottom, maxHeight + diff)
    if (top < maxHeight + diff) {
      if (diffBottom > (maxHeight + diff)) {
        promotionDetailPlacemen.value = 'bottom-end'
      } else {
        if (top >= diffBottom) {
          promotionDetailPlacemen.value = 'top-end'
          maxHeight = top - diff
        } else {
          promotionDetailPlacemen.value = 'bottom-end'
          maxHeight = diffBottom - diff
        }
      }
    }
    console.log('maxHeight', maxHeight)
    promotionDetailMaxHeight.value = `${maxHeight}px`
  }

  return {
    promotionDetailMaxHeight,
    promotionDetailPlacemen,
    updatePromotionDetailStyles,
  }
}
