import { getSessionStorage } from '@shein/common-function'

//google自动登录冷却期缓存
const DISABLE_AUTO_SELECT = 'disableAutoSelect'

function getVisitConfrim() {
  return getSessionStorage('visitConfrim')
}

export function setGoogleOneTapCatch() {
  localStorage.setItem(DISABLE_AUTO_SELECT, Date.now())
  localStorage.removeItem('is_show_init_passwd')
}

//  登陆成功清除访客模式
export const clearVisitConfrim = async () => {
  if (getVisitConfrim && getVisitConfrim() == 1) {
    sessionStorage.removeItem('visitConfrim')
  }
}
