<template>
  <span style="vertical-align: middle; display: inline-block; line-height: 0; font-size: 14px">
    <svg
      style="width: auto; height: 1em"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7712 4.89648L11.4783 5.60359L6.99995 10.0819L2.52161 5.60359L3.22871 4.89648L6.99995 8.66804L10.7712 4.89648Z"
        fill="#1C1C1C"
      ></path>
    </svg>
  </span>
</template>
