import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { timeTransformer } from '@shein/common-function'

const cccPostkeyObj = {
  2: '',
  3: 'CccMenCategory',
  4: 'CccKidsCategory',
  5: 'CccHomeCategory',
  6: 'CccPlussizeCategory',
  10: 'CccAlltabCategory',
  7: 'CccxSwimwearCategory',
  8: 'CccxSaleCategory',
  9: 'CccxBeautyCategory',
  11: 'CccxOtherCategory',
}

const typeMapToTarget = {
  real: 'real',
  realOne: 'real',
  realTwo: 'real',
  itemPicking: 'itemPicking',
  article: 'article',
  webLink: 'url',
  survey: 'survey',
  activity: 'activity',
  game: 'game',
  singleReplay: 'singleReplay',
  singlePreview: 'singlePreview',
  singleVideo: 'singleVideo',
  lookBook: 'lookBook',
  sku: 'goodsid',
  category: 'category',
  prime: 'prime',
  activityNew: 'activityNew',
  sheinxDesignerInformation: 'sheinxDesignerInformation',
  sheinxDesignerList: 'sheinxDesignerList',
}

const typeMapDefault = {
  noJump: 'noJump_0',
  trial: 'freetrial_0',
  giftCard: 'giftcard_0',
  dailyNew: 'dailynew_0',
  whatsnew: 'dailynew_0',
  whatsNew: 'dailynew_0',
  flashSale: 'flashsale_0',
  lightningDeal: 'flashsale_0',
  gals: 'gals_0',
  video: 'media_0',
  signPage: 'checkin_0',
  comingSoon: 'comingsoon_0',
  videoList: 'videoList_0',
  liveEntrace: 'liveEntrace_0',
  liveList: 'liveList_0',
  couponCenter: 'couponcenter_0',
  userInfoEdit: 'selfinformation_0',
  sheinxDesignerList: 'sheinxDesignerList',
}

export default function useAnalysis(getUserSaAbt) {
  const getAbTest = item => {
    const isFn = typeof getUserSaAbt === 'function'
    if (isFn) {
      const channelType = item.id
      const saPosKeyList = [cccPostkeyObj[channelType]]
      if (channelType != 10) {
        saPosKeyList.push('CccAlltabCategory')
        if (item.autoMap) {
          saPosKeyList.push('NaviRec')
        }
      }
      // const abtest = abtservice.getUserAbtResultForAnalysis({ posKeys: [...new Set(saPosKeyList)].join(',') }).sa
      const abtest = getUserSaAbt({ posKeys: [...new Set(saPosKeyList)].join(',') })?.sa
      return abtest
    }
    return undefined
  }

  const getTopNavSa = (item, index) => {
    if (!item) return
    const top_category = `${index + 1}\`${item.channelName || item.name}\`${item.id}\`${item.crowdId || 0}`
    const abtest = getAbTest(item)
    const saData = { top_category }
    if (abtest) saData.abtest = abtest
    return saData
  }

  return {
    getAbTest,
    getTopNavSa,
  }
}

export const getSaJumpTypeNew = item => {
  const typeMap = {
    discountList: 'sheinpicks_' + (item.hrefTarget || '0'),
    promoDiscount: 'sheinpicks_' + (item.hrefTarget || '0'),
    sheinxDesignerInformation: 'sheinxDesignerInformation' + (item.hrefTarget || '0'),
    ...typeMapDefault,
  }

  let saResContent = '0'
  if (['real', 'realOne', 'realTwo', 'itemPicking'].indexOf(item.hrefType) > -1 || typeMapToTarget[item.hrefType]) {
    if (['real', 'realOne', 'realTwo', 'itemPicking'].indexOf(item.hrefType) > -1) {
      let hrefType = item.hrefType.split('_')[0]
      saResContent = `${typeMapToTarget[hrefType]}_${item.hrefTarget || 0}`
    } else if (item.hrefType === 'activity') {
      saResContent = `${typeMapToTarget[item.hrefType]}_${item.hrefTarget.toString().trim() || 'noJump'}`
    } else {
      saResContent = `${typeMapToTarget[item.hrefType]}_${item.hrefTarget || 0}`
    }
  } else if (typeMap[item.hrefType]) {
    saResContent = typeMap[item.hrefType]
  } else if (item.hrefType === 'special') {
    // special 类型需要重新拿 hrefType2 来判断
    saResContent = typeMap[item.hrefType2] || item.hrefType + '_0'
  } else if (item.hrefType) {
    saResContent = item.hrefType + '_0'
  }

  return saResContent
}
const getCategoryParam = ({ categoryType = 0, categoryList = [] } = {}) => {
  const catIdsArray = []
  categoryList &&
    categoryList.forEach(item => {
      if (categoryType == '2' && item.val) {
        catIdsArray.push(item.shein_cat_id)
      }
    })
  return catIdsArray.length ? decodeURIComponent(catIdsArray.join(',')) : ''
}
const userPathReplaceSpeCha = function (str) {
  return typeof str === 'string' && str.replace(/\s*[#%&]\s*/g, '-')
}

const userPathGetTitle = function (url) {
  const vscReg = /([^\/]+)-[vs]?c-\d+\.html/
  const promoReg = /\/?(promo-discount|[a-z]+-picks|romwe-[a-z]+)\.html/
  const dailyReg = /\/?daily-new\.html.*daily=(\d+-\d+-\d+)/
  if (vscReg.test(url)) return url.match(vscReg)[1]
  else if (promoReg.test(url)) return url.match(promoReg)[1]
  else if (dailyReg.test(url)) return url.match(dailyReg)[1]
  else if (/\/?daily-new\.html/.test(url)) return 'whats new'
  else return ''
}
const fullZero = num => {
  return num < 10 ? `0${num}` : num
}

export const getGenerateSaPs = ({
  position = 0,
  active,
  activeParentCatePosition,
  activeChildCatePosition,
  isChildCate,
} = {}) => {
  const channelIndex = fullZero(Number(active.channelIndex) + 1)
  const oneCateParentIndex = fullZero(Number(activeParentCatePosition) + 1)
  const oneCateChildIndex = fullZero(Number(activeChildCatePosition) + 1)
  const index = String(position).indexOf('_') > -1 ? position : Number(position) + 1

  return `tab${channelIndex}navbar${oneCateParentIndex}${isChildCate ? `_${oneCateChildIndex}` : ''}menu01dir${index}`
  //  +
  //     `navbar${oneCateParentIndex}` +
  //     `${isChildCate ? `_${oneCateChildIndex}` : ''}` +
  //     'menu01' +
  //     `dir${index}`
}

export function formatInfoByHrefType({ cate, index, state, dailyObj = {} } = {}, channel, oneCate, twoCate) {
  const locationInfo = { hrefType: cate?.hrefType, jumpType: 'redirect', url: '', query: '', productsource: '' }
  const analysisInfo = { categoryIndex: '', target: '', saInfo: '', gaInfo: '', saContent: '', webLink: false }
  const { langPath, language = {} } = state || {}
  let cateTarget = null
  let newCateAlt = cate.alt
  switch (cate?.hrefType) {
    case 'noJump':
      analysisInfo['categoryIndex'] = '-'
      analysisInfo['target'] = '-'
      analysisInfo['gaInfo'] = '-`'
      break
    case 'real':
      analysisInfo['categoryIndex'] = '1'
      analysisInfo['target'] = cate.hrefTarget
      analysisInfo['gaInfo'] = `真实分类-\`${analysisInfo['target']}`
      locationInfo['jumpType'] = 'router'
      locationInfo['url'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
      if (!newCateAlt) {
        newCateAlt = userPathGetTitle(cate.relativeUrl || cate.catUrl)
      }
      break
    case 'itemPicking':
      analysisInfo['categoryIndex'] = '4'
      analysisInfo['target'] = cate.hrefTarget
      analysisInfo['gaInfo'] = `选品页-\`${analysisInfo['target']}`
      locationInfo['jumpType'] = 'router'
      locationInfo['url'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
      if (!newCateAlt) {
        newCateAlt = userPathGetTitle(cate.relativeUrl || cate.catUrl)
      }
      break
    case 'whatsNew':
    case 'whatsnew':
      cateTarget = getCategoryParam({ categoryType: cate.categoryType, categoryList: cate.categoryList, state })
      analysisInfo['categoryIndex'] = '3'
      analysisInfo['target'] = 'whats_new'
      analysisInfo['gaInfo'] = `特殊分类-\`${cateTarget || 'whats new'}`
      locationInfo['jumpType'] = 'router'
      locationInfo['url'] = `${langPath}/daily-new.html`
      locationInfo['query'] = `cat_ids=${cateTarget}`
      if (!newCateAlt) {
        newCateAlt = 'whats new'
      }
      break
    case 'dailyNew': {
      const dailyKey = Object.keys(dailyObj)[index]

      // 当天或昨天的日期使用多语言文案描述
      const today = timeTransformer({
        time: Date.now(),
        defaultCode: 'A-5',
      })
      const yesterday = timeTransformer({
        time: Date.now() - 24 * 60 * 60 * 1000,
        defaultCode: 'A-5',
      })

      if (dailyKey === today) {
        dailyObj[dailyKey] = language.SHEIN_KEY_PWA_16288 // Today 多语言文案
      } else if (dailyKey === yesterday) {
        dailyObj[dailyKey] = language.SHEIN_KEY_PWA_15855 // Yesterday 多语言文案
      }

      newCateAlt = dailyObj[dailyKey]
      const catId = '' // TODO catId如何获取
      analysisInfo['categoryIndex'] = '3'
      analysisInfo['target'] = 'daily_new'
      analysisInfo['gaInfo'] = '特殊分类-`daily new'
      locationInfo['jumpType'] = 'router'
      locationInfo['url'] = `${langPath}/daily-new.html?daily=${dailyKey}` // 列表页默认分类为 What's New 因此 child_cat_id 设置 0
      locationInfo['query'] = `cat_ids=${catId}`
      break
    }
    case 'promoDiscount':
    case 'discountList':
      analysisInfo['categoryIndex'] = '3'
      analysisInfo['target'] = 'shein_picks'
      analysisInfo['gaInfo'] = '特殊分类-`SheinPicks'
      locationInfo['jumpType'] = 'router'
      console.log('SheinPicks', cate)
      // locationInfo['url'] = transformPromodiscountUrl(`${langPath}/shein-picks.html` + (cate.hrefTarget ? `?hrefTarget=${cate.hrefTarget}` : ''), { PICKS_LINKS })

      // 项目兼容代码
      if (!newCateAlt) {
        newCateAlt = 'shein-picks'
      }
      break
    case 'game':
      analysisInfo['categoryIndex'] = '7'
      analysisInfo['target'] = cate.hrefTarget
      analysisInfo['gaInfo'] = `链接-\`${cate.hrefTarget}`
      locationInfo['url'] = `${langPath}/${cate.gameType}/${cate.hrefTarget}`
      locationInfo['jumpType'] = 'redirect'
      break
    case 'activity':
      // case 'game':
      analysisInfo['categoryIndex'] = '5'
      analysisInfo['target'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
      analysisInfo['gaInfo'] = `campaign-\`${cate.hrefTarget}`
      locationInfo['url'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
      // 专题跳转修改为单页面跳转
      locationInfo['jumpType'] = 'router'
      break
    case 'activityOld':
      // case 'game':
      analysisInfo['categoryIndex'] = '5'
      analysisInfo['target'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
      analysisInfo['gaInfo'] = `campaign-\`${cate.hrefTarget}`
      locationInfo['url'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
      // 专题跳转修改为单页面跳转
      locationInfo['jumpType'] = 'router'
      break
    // 跳转新专题
    case 'activityNew':
      analysisInfo['categoryIndex'] = '12'
      analysisInfo['target'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
      analysisInfo['gaInfo'] = `campaigns-\`${cate.hrefTarget}`
      locationInfo['url'] = `${langPath}${cate.relativeUrl || cate.catUrl}`
      // 专题跳转修改为单页面跳转
      locationInfo['jumpType'] = 'router'
      break
    case 'flashSale':
      analysisInfo['categoryIndex'] = '6'
      analysisInfo['target'] = 'flash_sale'
      analysisInfo['gaInfo'] = 'FalshSale-`flash sale'
      locationInfo['url'] = `${langPath}/flash-sale.html`
      locationInfo['query'] = `cat_ids=${getCategoryParam({
        categoryType: cate.categoryType,
        categoryList: cate.categoryList,
        state,
      })}`
      locationInfo['jumpType'] = 'router'
      break
    case 'giftCard':
      analysisInfo['categoryIndex'] = '8'
      analysisInfo['target'] = 'giftcard'
      locationInfo['url'] = `${langPath}/giftcard.html`
      analysisInfo['gaInfo'] = '链接-`gift card'
      locationInfo['jumpType'] = 'redirect'
      break
    case 'webLink':
      analysisInfo['categoryIndex'] = '7'
      analysisInfo['target'] = cate.hrefTarget
      analysisInfo['gaInfo'] = `链接-\`${cate.hrefTarget}`
      locationInfo['url'] = cate.hrefTarget
      locationInfo['webLink'] = true
      break
    case 'lookBook':
      analysisInfo['categoryIndex'] = '10'
      analysisInfo['target'] = 'lookBook'
      locationInfo['url'] = `${langPath}/shop-multiple-sets-${cate.hrefTarget}.html`
      analysisInfo['gaInfo'] = '特殊分类-`lookBook'
      locationInfo['jumpType'] = 'router'
      break
    case 'article':
      analysisInfo['categoryIndex'] = '10'
      analysisInfo['target'] = 'article'
      locationInfo['url'] = `${langPath}/${cate.selectTypeName || 'article'}-a-${cate.hrefTarget}.html`
      analysisInfo['gaInfo'] = '链接-`article'
      locationInfo['jumpType'] = 'redirect'
      break
    case 'trial':
      analysisInfo['categoryIndex'] = '9'
      analysisInfo['target'] = 'trial'
      locationInfo['url'] = `${langPath}/free-trial-center/index`
      analysisInfo['gaInfo'] = '链接-`trial'
      locationInfo['jumpType'] = 'router'
      break
    case 'prime':
      analysisInfo['categoryIndex'] = '11'
      analysisInfo['target'] = 'prime'
      locationInfo['url'] = `${langPath}/user/prime`
      analysisInfo['gaInfo'] = '链接-`prime'
      locationInfo['jumpType'] = 'router'
      break
    case 'sheinxDesignerInformation':
      analysisInfo['categoryIndex'] = '12'
      analysisInfo['target'] = cate.hrefTarget
      locationInfo['url'] = `${langPath}/designer/${cate.hrefTarget}`
      analysisInfo['gaInfo'] = '链接-`sheinxDesignerInformation'
      locationInfo['jumpType'] = 'router'
      break
    case 'sheinxDesignerList':
      analysisInfo['categoryIndex'] = '13'
      analysisInfo['target'] = 'sheinxDesignerList'
      locationInfo['url'] = `${langPath}/designer_list`
      analysisInfo['gaInfo'] = '链接-`sheinxDesignerList'
      locationInfo['jumpType'] = 'redirect'
      break
    default:
      analysisInfo['gaInfo'] = '-`'
  }
  const saInfo = `${String(cate.position).indexOf('_') > -1 ? cate.position : Number(cate.position) + 1}\`${
    analysisInfo['categoryIndex'] || '-'
  }\`${analysisInfo['target'] || '-'}`
  const saContent = getSaJumpTypeNew(cate)
  const ps = getGenerateSaPs({
    position: cate.position,
    active: { channelIndex: channel.index },
    activeParentCatePosition: oneCate.index,
    activeChildCatePosition: index,
    isChildCate: false,
  })
  analysisInfo['saContent'] = saContent
  analysisInfo['saInfo'] = saInfo
  analysisInfo['ps'] = ps
  const { channelName } = channel.channel

  let isAutoValue = 0,
    goodsId = 0,
    imgId = 0,
    rec_mark = ''
  const mapId = twoCate?.id || ''
  if (twoCate?.name) {
    // 处理标题节点点击
    isAutoValue = twoCate?.metaData?.autoRecommend ? twoCate?.metaData?.autoRecommendType : 0
    goodsId = ''
    imgId = ''
    rec_mark = '-' // 流量标识符
  } else {
    isAutoValue = twoCate?.autoRecommend ? twoCate?.autoRecommendType : 0
    goodsId = twoCate?.cover?.goodsId || 0
    imgId = twoCate?.cover?.imgId || 0
    rec_mark = twoCate?.categoryType?.rec_mark || '-' // 流量标识符
  }
  const second_category_list = [saInfo, isAutoValue, goodsId, mapId, imgId, rec_mark].join('`')

  // const oneCateName = oneCateContent?.firstFloorContent?.props?.metaData?.categoryName
  const oneCateName = oneCate.cate?.firstFloorContent?.props?.metaData?.categoryName
  const content_list = `fc=${channelName}\`sc=${oneCateName}\`tc=0\`oc=${twoCate?.categoryName || 0}\`ps=${
    ps || 0
  }\`jc=${saContent || 0}`
  // console.log('content_list',content_list);
  locationInfo['query'] += cate.adp ? `&adp=${cate.adp}` : ''
  const gaInfoArr = (analysisInfo?.gaInfo || '-`').split('-`')
  const productsourceArr = [
    gaInfoArr[0],
    channelName,
    oneCateName,
    analysisInfo.isWebLink ? encodeURIComponent(gaInfoArr[1]) : gaInfoArr[1],
  ]
  locationInfo['productsource'] = productsourceArr.join('-')
  if (['real', 'itemPicking', 'whatsNew', 'dailyNew', 'promoDiscount'].includes(cate.hrefType)) {
    let cateTitle = '' // TODO 如何获取
    let userpath = `>${channelName}>${oneCateName}>`
    if (cate.type == 2) userpath += cateTitle
    else if (cateTitle) userpath += `${cateTitle}>${newCateAlt}`
    else userpath += newCateAlt
    locationInfo['userpath'] = userPathReplaceSpeCha(userpath)
    locationInfo['userpathFrom'] = { cate: language.SHEIN_KEY_PWA_14980, shop: language.SHEIN_KEY_PWA_14979 }
  }

  newCateAlt = typeof newCateAlt === 'string' ? newCateAlt.trim() : ''

  return {
    content_list,
    second_category_list,
    locationInfo,
    ...analysisInfo,
    newCateAlt,
  }
}

const screens = [
  [0,736,18],
  [736,1024,21],
  [1024,Infinity,24],
]
function getBffImgCount(){
  let count = 21
  if (typeof window === 'undefined') return count
  const height = window.screen.height
  for (let i = 0; i < screens.length; i++) {
    const range = screens[i]
    if(height > range[0] && height <= range[1]){
      count = range[2]
      break;
    }
  }
  return count
}

// 给二级数据增加埋点相关字段信息
export const appendTwoCateSa = (data, channel, oneCate) => {
  if (typeof window === 'undefined') return

  const appConfigs = useAppConfigs()
  const { langPath, language = {} } = appConfigs.$envs
  const state = { langPath, language }
  const { contents = [] } = data || {}
  // console.log('appendTwoCateSa',contents);
  let item_loc = 0 // 当前位置
  let lazyIndex = 0
  if (contents?.length > 0) {
    const MAX_PRELOAD = getBffImgCount()
    for (let i = 0; i < contents.length; i++) {
      const { child, props } = contents[i]
      const items = child?.[0]?.props?.items || props?.items || [] // 图片数据返回位置不一样
      // 处理标题节点
      const cate = Object.assign({}, props?.metaData?.categoryType, { position: item_loc })
      const contentAnalysis = formatInfoByHrefType({ cate, index: item_loc, state }, channel, oneCate, props)
      props.metaData.contentAnalysis = contentAnalysis
      props.metaData.contentAnalysis.item_loc = item_loc
      props.metaData.isLazy = item_loc >= MAX_PRELOAD // 根据屏幕分辨率计算前${MAX_PRELOAD}个图片，轮播图不用懒加载请求
      item_loc++
      // 处理子节点
      if (items?.length > 0) {
        for (let j = 0; j < items.length; j++) {
          const cate = Object.assign({}, items[j]?.categoryType, { position: item_loc })
          const contentAnalysis = formatInfoByHrefType({ cate, index: item_loc, state }, channel, oneCate, items[j])
          items[j].contentAnalysis = contentAnalysis
          items[j].contentAnalysis.item_loc = item_loc
          items[j].isLazy = lazyIndex >= MAX_PRELOAD // 根据屏幕分辨率计算前${MAX_PRELOAD}个二级导航图片不用懒加载请求
          item_loc++
          lazyIndex++
        }
      }
    }
  }
}
