
export const useAccountAnalysis = (triggerNotice) => {
  const exposeRememberAccountPop = () => {
    triggerNotice({
      id: 'expose_remember_account_pop',
    })
  }

  const clickRememberAccountPopNo = () => {
    triggerNotice({
      id: 'click_remember_account_pop_no',
    })
  }

  const clickRememberAccountPopYes = () => {
    triggerNotice({
      id: 'click_remember_account_pop_yes',
    })
  }

  const clickSwitchAccount = () => {
    triggerNotice({
      id: 'click_switch_account',
    })
  }
  const exposeSwitchAccount = () => {
    triggerNotice({
      id: 'expose_switch_account',
    })
  }

  return {
    exposeRememberAccountPop,
    clickRememberAccountPopNo,
    clickRememberAccountPopYes,
    clickSwitchAccount,
    exposeSwitchAccount,
  }

}
