import standard from './sources/standard'
import { addSubscribe } from '@shein-aidc/bs-sdk-libs-da-event-center'
import type { AS_Category } from '../../types'

let saInstance = {}
export function useAnalysis(input?: AS_Category.AnalysisSource): AS_Category.AnalysisInstance {
  const analysisInstance = addSubscribe(standard, input)
  saInstance = analysisInstance
  return analysisInstance
}

export function getSaInstance(){
  return saInstance
}