const searchTerms = [
  {
    brand: null,
    crowdId: null,
    siteLang: null,
    terminal: null,
    weight: null,
    word: 'Going Out Tops',
    imgSrc: '',
    pageId: null,
    pageType: null,
    pageUrl: null,
    sort: null,
    type: 'insitehot',
    from: 'search',
    mall_code_list: null,
    route_url: null,
    cateIds: null,
    categoryChannel: null,
    wordLabel: {
      icon: 'https://img.shein.com/images3/2023/10/27/12/1698394178d38f921804439541f3c021cfef646c7c.png',
      labelType: 'hot',
      labelFront: '',
    },
  },
  {
    brand: null,
    crowdId: null,
    siteLang: null,
    terminal: null,
    weight: null,
    word: 'Halloween',
    imgSrc: '',
    pageId: null,
    pageType: null,
    pageUrl: null,
    sort: null,
    type: 'insitehot',
    from: 'search',
    mall_code_list: null,
    route_url: null,
    cateIds: null,
    categoryChannel: null,
    wordLabel: null,
  },
  {
    brand: null,
    crowdId: null,
    siteLang: null,
    terminal: null,
    weight: null,
    word: 'Dresses',
    imgSrc: '',
    pageId: null,
    pageType: null,
    pageUrl: null,
    sort: null,
    type: 'insitehot',
    from: 'search',
    mall_code_list: null,
    route_url: null,
    cateIds: null,
    categoryChannel: null,
    wordLabel: {
      icon: 'https://img.shein.com/images3/2023/10/27/12/1698394178d38f921804439541f3c021cfef646c7c.png',
      labelType: 'hot',
      labelFront: '',
    },
  },
  {
    brand: null,
    crowdId: null,
    siteLang: null,
    terminal: null,
    weight: null,
    word: 'Skirt',
    imgSrc: '',
    pageId: null,
    pageType: null,
    pageUrl: null,
    sort: null,
    type: 'insitehot',
    from: 'search',
    mall_code_list: null,
    route_url: null,
    cateIds: null,
    categoryChannel: null,
    wordLabel: {
      icon: 'https://img.shein.com/images3/2023/10/27/12/1698394178d38f921804439541f3c021cfef646c7c.png',
      labelType: 'hot',
      labelFront: '',
    },
  },
  {
    brand: null,
    crowdId: null,
    siteLang: null,
    terminal: null,
    weight: null,
    word: 'Shoes',
    imgSrc: '',
    pageId: null,
    pageType: null,
    pageUrl: null,
    sort: null,
    type: 'insitehot',
    from: 'search',
    mall_code_list: null,
    route_url: null,
    cateIds: null,
    categoryChannel: null,
    wordLabel: {
      icon: 'https://img.shein.com/images3/2023/10/27/f5/1698394226527e3792513f865e0b2d6124c9e7a646.png',
      labelType: 'trend',
      labelFront: '',
    },
  },
  {
    brand: null,
    crowdId: null,
    siteLang: null,
    terminal: null,
    weight: null,
    word: 'Earrings',
    imgSrc: '',
    pageId: null,
    pageType: null,
    pageUrl: null,
    sort: null,
    type: 'insitehot',
    from: 'search',
    mall_code_list: null,
    route_url: null,
    cateIds: null,
    categoryChannel: null,
    wordLabel: {
      icon: 'https://img.shein.com/images3/2023/10/27/f5/1698394226527e3792513f865e0b2d6124c9e7a646.png',
      labelType: 'trend',
      labelFront: '',
    },
  },
  {
    brand: null,
    crowdId: null,
    siteLang: null,
    terminal: null,
    weight: null,
    word: 'Shorts',
    imgSrc: '',
    pageId: null,
    pageType: null,
    pageUrl: null,
    sort: null,
    type: 'insitehot',
    from: 'search',
    mall_code_list: null,
    route_url: null,
    cateIds: null,
    categoryChannel: null,
    wordLabel: {
      icon: 'https://img.shein.com/images3/2023/10/27/12/1698394178d38f921804439541f3c021cfef646c7c.png',
      labelType: 'hot',
      labelFront: '',
    },
  },
  {
    brand: null,
    crowdId: null,
    siteLang: null,
    terminal: null,
    weight: null,
    word: 'Shirts',
    imgSrc: '',
    pageId: null,
    pageType: null,
    pageUrl: null,
    sort: null,
    type: 'insitehot',
    from: 'search',
    mall_code_list: null,
    route_url: null,
    cateIds: null,
    categoryChannel: null,
    wordLabel: {
      icon: 'https://img.shein.com/images3/2023/10/27/12/1698394178d38f921804439541f3c021cfef646c7c.png',
      labelType: 'hot',
      labelFront: '',
    },
  },
]

export { searchTerms }
