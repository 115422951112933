<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M0.375 3.375L3.32143 6.28572L5.89286 3.71429L10.75 8.57144"
      stroke="white"
    />
    <path
      d="M10.7497 5.71423V8.57138H7.89258"
      stroke="white"
    />
  </svg>
</template>
