<template>
  <div class="bs-cate-skeleton">
    <h6 class="bs-cate-type_title"></h6>
    <div class="bs-cate-type_content">
      <div
        v-for="item in 20"
        :key="item"
        class="bs-cate-type_img-link"
      >
        <span class="bs-cate-type_img"></span>
        <span class="bs-cate-type_text">name</span>
      </div>
    </div>
  </div>
</template>

<style lang="less" scope>
@skeletonColor: #f5f5f5;
.bs-cate-skeleton {
  padding-top: 20px;
  .bs-cate-type {
    &_title {
      width: 200px;
      line-height: 16px;
      background-color: #f5f5f5;
      color: @skeletonColor;
    }
    &_img {
      background-color: @skeletonColor;
    }
    &_text {
      background-color: @skeletonColor;
      color: @skeletonColor;
      width: 64px;
      line-height: 14px;
      margin-top: 4px;
    }
  }
}
</style>
