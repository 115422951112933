<template>
  <span class="bsc-resize-font">
    <slot></slot>
  </span>
</template>

<script setup>
import { ref, provide, onMounted, nextTick, getCurrentInstance } from 'vue'

/**
 * @description 自适应字体大小,缩放到最小值后不再缩放溢出隐藏
 * @example <ResizeFont><ResizeFontText :init-font-size="14" :resize-font-min="10" :resize-font-step="2">内容</ResizeFontText>
 */

const emit = defineEmits(['overflowing'])

const children = ref([])
const instance = ref(null)

provide('registerComponent', (child) => {
  children.value.push(child)
})

/**
 * 处理自适应字体大小-缩小
 */
const handleResizeFontSize = (element) => {
  const isOverflowing = element.clientWidth < getChildrenWidth()
  emit('overflowing', isOverflowing)
  if (isOverflowing) {
    resizeFontSize()
    nextTick(() => {
      !getResizeDone() && handleResizeFontSize(instance.value?.proxy.$el)
    })
  }
}

/**
 * 处理自适应字体大小-父组件需要根据屏幕放大自适应字体大小时调用
 */
const handleEnlargeFontSize = (element) => {
  const isOverflowing = element.offsetWidth > getChildrenWidth()
  emit('overflowing', isOverflowing)
  if (isOverflowing) {
    enlargeFontSize()
    nextTick(() => {
      !getResizeDone() && handleEnlargeFontSize(instance.value?.proxy.$el)
    })
  }
}

 /**
 * 调用当前子组件的 resizeFontSize 方法
 */
const resetSizeDone = () => {
  children.value.forEach(child => {
    child.isResizeDone = false
  })
}

 /**
 * 重置子组件的状态
 */
const resizeFontSize = () => {
  children.value.forEach(child => {
    child.resizeFontSize()
  })
}

const enlargeFontSize = () => {
  children.value.forEach(child => {
    child.enlargeFontSize()
  })
}

const getResizeDone = () => {
  return children.value.every(child => child.isResizeDone)
}

const getChildrenWidth = () => {
  let totalWidth = 0
  children.value.forEach(child => {
    const style = window.getComputedStyle(child.$el)
    const marginLeft = parseFloat(style.getPropertyValue('margin-left'))
    const marginRight = parseFloat(style.getPropertyValue('margin-right'))
    totalWidth = totalWidth + child.$el.offsetWidth + marginLeft + marginRight
  })
  return totalWidth
}

onMounted(() => {
  instance.value = getCurrentInstance()
  handleResizeFontSize(instance.value?.proxy.$el)
})

defineExpose({
  handleResizeFontSize,
  handleEnlargeFontSize,
  resetSizeDone,
})
</script>

<style lang="less">
.bsc-resize-font {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
