<template>
  <div
    v-if="couponInfo"
    class="bsc-mini-cart-full-coupon"
  >
    <Icon
      :name="couponAddOnIconName"
      :is-responsive-name="true"
      size="16px"
      class="bsc-mini-cart-full-coupon__icon"
    />
    <div class="bsc-mini-cart-full-coupon__content">
      <div
        class="bsc-mini-cart-full-coupon__tips"
        v-html="(couponInfo.promotionTips && couponInfo.promotionTips.html_text) || ''"
      ></div>
      <div
        v-if="couponInfo.data.isMeet == '0'"
        class="bsc-mini-cart-full-coupon__process"
      >
        <div
          class="bsc-mini-cart-full-coupon__process-inner"
          :style="{ width: `${processCouponCount}%` }"
        ></div>
      </div>
      <MiniCartCountDown
        v-if="couponInfo.data.is_count_down"
        :time-stamp="Number(couponInfo.data.end_time)"
        :language="language"
      />
    </div>
  </div>
</template>

<script name="BMiniCartFullCoupon" setup lang="ts">
import { computed, inject } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import MiniCartCountDown from './mini-cart-count-down.vue'

const mallCartInfo = inject<any>('mallCartInfo')
const language = inject<any>('language')

type CouponInfo = {
  promotionTips: {html_text: string},
  data: {
    isMeet: string,
    is_count_down: number,
    end_time: string,
    additionInfoList: {showTitlePrice: {amount: string}}[],
    progressDiffAmount: {amount: string},
  },
} | null
const couponInfo = computed<CouponInfo>(() => {
  let couponInfo = null
  mallCartInfo.value.bottomPromotionData?.forEach(item => {
    let target = item.contentData?.find(content => content?.groupHeadInfo?.data?.type_id == 10000)
    if (target) {
      couponInfo = target.groupHeadInfo
    }
  })
  return couponInfo
})

const couponAddOnIconName = computed(() => {
  return couponInfo.value?.data?.isMeet == '1'
    ? 'sui_icon_selected_16px'
    : 'sui_icon_coupon_discount_16px'
})

const processCouponCount = computed(() => {
  if (!couponInfo.value) return 0
  let targetPrice = Number(couponInfo.value.data?.additionInfoList?.[0].showTitlePrice?.amount)
  let progressDiffAmount = Number(couponInfo.value.data?.progressDiffAmount?.amount)
  let process = (targetPrice - progressDiffAmount) / targetPrice * 100
  if(process < 0) process = 0
  else if(process > 100) process = 100
  return process
})
</script>

<style lang="less">
.bsc-mini-cart-full-coupon {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #FFF6F3;
  color: #222222;
  .bsc-mini-cart-full-coupon__icon {
    margin-right: 8px;
  }
  .bsc-mini-cart-full-coupon__content {
    width: 100%;
    overflow: hidden;
  }
  .bsc-mini-cart-full-coupon__tips {
    font-size: 13px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .bsc-mini-cart-full-coupon__process {
    width: 100%;
    height: 3px;
    background-color: #bbbbbb;
    border-radius: 2px;
    margin-top: 4px;
  }
  .bsc-mini-cart-full-coupon__process-inner {
    height: 100%;
    background-color: #222222;
    border-radius: 2px;
    transition: width 0.5s linear;
  }
}
</style>
