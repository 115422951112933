// Observable class
class Observable<T> {
  private _subscribe: (observer: Observer<T>) => Subscription

  constructor(subscribe: (observer: Observer<T>) => Subscription) {
    this._subscribe = subscribe
  }

  subscribe(observer: Observer<T> | ((value: T) => void)): Subscription {
    // If observer is a function, assume it's the 'next' handler
    if (typeof observer === 'function') {
      return this._subscribe({ next: observer })
    }
    return this._subscribe(observer)
  }
}

// Observer interface (typed for flexibility)
interface Observer<T> {
  next?: (value: T) => void
  error?: (err: any) => void
  complete?: () => void
}

// Subscription interface
interface Subscription {
  unsubscribe: () => void
}

// Factory function for interval Observable
function createIntervalObservable(intervalTime: number): Observable<number> {
  return new Observable<number>((observer: Observer<number>) => {
    let index = 0
    const id = setInterval(() => {
      if (observer.next) {
        observer.next(index++)
      }
    }, intervalTime)

    // Return an unsubscribe function to stop the interval
    return {
      unsubscribe: () => clearInterval(id),
    }
  })
}

export {
  Observable,
  Observer,
  Subscription,
  createIntervalObservable,
}

// Example of subscribing
// const observable = createIntervalObservable(4000)

// const subscription = observable.subscribe({
//   next: (index: number) => console.log(index),
//   error: (err: any) => console.error('Error:', err),
//   complete: () => console.log('Completed'),
// })

// // Unsubscribe after 20 seconds
// setTimeout(() => {
//   subscription.unsubscribe()
// }, 20000)
