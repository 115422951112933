<template>
  <span :style="resizeFontStyle">
    <slot>{{ content }}</slot>
  </span>
</template>

<script setup>
import { ref, computed, onMounted, inject, getCurrentInstance } from 'vue'

/**
 * @description 自适应字体大小,缩放到最小值后不再缩放溢出隐藏
 * @example <ResizeFont><ResizeFontText :init-font-size="14" :resize-font-min="10" :resize-font-step="2">内容</ResizeFontText>
 */

const props = defineProps({
  initFontSize: {
    type: Number,
    default: 18,
  },
  resizeFontMin: {
    type: Number,
    default: 12,
  },
  resizeFontStep: {
    type: Number,
    default: 2,
  },
  content: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['resizeDone'])

const registerComponent = inject('registerComponent')

const resizeFont = ref(props.initFontSize)
const isResizeDone = ref(false)

const resizeFontStyle = computed(() => ({
  fontSize: `${resizeFont.value}px`,
}))

onMounted(() => {
  const instance = getCurrentInstance()
  registerComponent({
    $el: instance?.proxy?.$el,
    resizeFontSize,
    enlargeFontSize,
    isResizeDone,
  })
})

const resizeFontSize = () => {
  if (isResizeDone.value) return
  if (resizeFont.value <= props.resizeFontMin) {
    isResizeDone.value = true
    emit('resizeDone')
    return
  }
  resizeFont.value = Math.max(resizeFont.value - props.resizeFontStep, props.resizeFontMin)
}

const enlargeFontSize = () => {
  if (isResizeDone.value) return
  if (resizeFont.value >= props.initFontSize) {
    isResizeDone.value = true
    emit('resizeDone')
    return
  }
  resizeFont.value = Math.min(resizeFont.value + props.resizeFontStep, props.initFontSize)
}
</script>
