<template>
  <div class="bsc-mini-cart-empty">
    <img
      src="https://img.ltwebstatic.com/images3_ccc/2024/09/14/c4/1726308103afb22656373fc035549a3b184a4363c0.png"
      alt="empty-cart"
      class="bsc-mini-cart-empty__icon"
    />
    <p class="bsc-mini-cart-empty__title">{{ language.SHEIN_KEY_PC_14572 }}</p>
    <div
      v-if="!isLogin"
      class="bsc-mini-cart-empty__content"
    >
      <span>{{ language.SHEIN_KEY_PC_14569 }}&nbsp;</span>
      <a @click.stop.prevent="onClick">
        {{ language.SHEIN_KEY_PC_14570 }}
      </a>
      <span>&nbsp;{{ language.SHEIN_KEY_PC_14571 }}</span>
    </div>
  </div>
</template>

<script name="BMiniCartEmpty" setup lang="ts">
defineProps({
  language: {
    type: Object,
    required: true,
  },
  isLogin: {
    type: Boolean,
    required: true,
  },
})

const onClick = () => {
  window.location.href = `/user/auth/login?from=emtpy_cart_module&redirection=${encodeURIComponent(window.location.pathname)}`
}
</script>

<style lang="less">
.bsc-mini-cart-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px 30px;
  width: 100%;
  min-height: 160px;
  background: #fff;
  .bsc-mini-cart-empty__icon {
    width: 64px;
    height: 64px;
    object-fit: contain;
  }
  .bsc-mini-cart-empty__title,
  .bsc-mini-cart-empty__content {
    margin-top: 20px;
    color: #666;
    text-align: center;
    a {
      text-decoration: underline;
      color: #333;
      cursor: pointer;
    }
  }
}
</style>
