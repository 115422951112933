<template>
  <div
    class="bsc-mini-cart-promotion-detail-product"
    :style="styles"
    @click="onClick"
  >
    <SCheckbox
      v-if="isShowCheckbox"
      v-model="checked"
      :disabled="info.goodType != '1'"
      size="size12"
      :outside-check="true"
      class="bsc-mini-cart-promotion-detail-product__checkbox"
    />
    <GoodsImg
      :img-url="info.goods_img"
      :is-lazy-load="false"
    >
      <template #bottom>
        <GoodsBelt
          v-if="isShowBelt && beltText"
          :is-show="true"
          size="SMALL"
          :belt-text="beltText"
          :bg-color="beltBgColor"
          :font-color="beltFontColor"
        />
      </template>
    </GoodsImg>
  </div>
</template>

<script name="BMiniCartPromotionDetailProduct" setup lang="ts">
import { ref, computed } from 'vue'
import { hexToRgb } from '@shein/common-function'
import { SCheckbox } from '@shein-aidc/sui-checkbox/laptop'
import { GoodsImg, GoodsBelt, useExpose, useProvideRegisterExpose } from '@shein-aidc/bs-cart-item-laptop'

const props = defineProps({
  info: {
    type: Object,
    default: () => ({}),
  },
  isShowCheckbox: {
    type: Boolean,
    default: true,
  },
  isShowBelt: {
    type: Boolean,
    default: true,
  },
  cartItemMap: {
    type: Object,
    default: () => ({}),
  },
  width: {
    type: String,
    default: '64px',
  },
  height: {
    type: String,
    default: '64px',
  },
})

const emit = defineEmits(['modifyCartCheckStatus'])

const styles = computed(() => {
  return {
    '--width': props.width,
    '--height': props.height,
  }
})


// ref
const tempRef = ref(null)
// methods
const registerExpose = useExpose(tempRef)

// provide
useProvideRegisterExpose(registerExpose)

const locked = ref(false)
const checked = computed(() => props.cartItemMap[props.info.id].is_checked == '1')
const onClick = () => {
  if (props.info.goodType != '1') return
  if (locked.value) return
  locked.value = true
  const callback = () => {
    locked.value = false
  }
  emit('modifyCartCheckStatus', { info: props.info, checked: !checked.value, callback })
}

const beltText = computed(() => {
  return props.info.productImgBelt?.text || ''
})
const beltBgColor = computed(() => {
  return props.info.productImgBelt?.bgColor ? hexToRgb({ hex: props.info.productImgBelt.bgColor, opa: props.info.productImgBelt?.bgColorAlpha }) : ''
})
const beltFontColor = computed(() => {
  return props.info.productImgBelt?.textColor || ''
})
</script>

<style lang="less">
.bsc-mini-cart-promotion-detail-product {
  position: relative;
  width: var(--width);
  height: var(--height);
  .bsc-mini-cart-promotion-detail-product__checkbox {
    position: absolute;
    top: 1px;
    right: 3px;
    z-index: 1;
    margin-right: 0;
    .sui-checkbox__label-text {
      display: none;
    }
  }
}
</style>
