import { computeCoordsFromPlacement } from './computeCoordsFromPlacement.ts'
import type { ComputePosition } from '../../types'

export { getSide, getAlignment } from './computeCoordsFromPlacement.ts'

export const computePosition: ComputePosition = (reference, floating, config) => {

  const rects = {
    reference: reference.getBoundingClientRect(),
    floating: floating.getBoundingClientRect(),
  }

  const { x, y } = computeCoordsFromPlacement(rects, config)

  return {
    x,
    y,
  }
}
