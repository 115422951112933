import { getLocalStorage, removeLocalStorage, removeSessionStorage } from '@shein/common-function'
import { logoutHandlePersonalizedSwitch } from './utils.js'

const USERINFO_KEY = 'auth_member'


export const removeAuthUserInfo = () => {
  try {
    if (getLocalStorage(USERINFO_KEY)) {
      removeLocalStorage(USERINFO_KEY)
    }
    // 清空ugid, 防止二次落地获取的数据为登录的数据
    removeSessionStorage('ugid')
    // 个性化开关处理
    logoutHandlePersonalizedSwitch()
  } catch (error) {
    console.log( error + 'removeAuthUserInfo error')
  }
}
