<script  name="BCartDiscountSave" lang="tsx">
import SaveSvg from '../svgs/save.vue'

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    style: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    return () => <span
      class="bsc-cart-discount__tag bsc-cart-discount__tag-save"
      style={props.style}
    >
      { props.text ? <span class="bsc-cart-discount__tag-text">{ props.text }</span> : [<SaveSvg />, <span class="bsc-cart-discount__tag-text">Save</span>] }
    </span>
  },
}
</script>

