<template>
  <p
    v-show="showCountDown"
    :class="[
      'bsc-mini-cart-count-down',
      'not-fsp-element',
      {
        'bsc-mini-cart-count-down_promotion': showType == 'inline-promotion',
        'bsc-mini-cart-count-down_active': diffDays == 0,
      }
    ]"
  >
    <span
      v-if="!hideTip"
      :class="{'bsc-mini-cart-count-down__activity-tips': showEndTips}"
    >
      {{ showEndTips ? language.SHEIN_KEY_PC_24840 : (showType == 'inline-promotion' ? '' : template('', language.SHEIN_KEY_PC_17111)) }}
    </span>
    <span class="bsc-mini-cart-count-down__content">
      <span v-show="diffDays">{{ daysComputed }}&nbsp;</span>
      <span :style="{'fontSize': fontSize}">{{ twoNums(diffHours) }}</span>:
      <span :style="{'fontSize': fontSize}">{{ twoNums(diffMinutes) }}</span>:
      <span :style="{'fontSize': fontSize}">{{ twoNums(diffSeconds) }}</span>
    </span>
  </p>
</template>
<script name="BMiniCartCountDown" setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { template } from '@shein/common-function'

const props = defineProps({
  countDown: {
    type: Object,
    required: false,
    default () {
      return {}
    },
  },
  timeStamp: {
    type: Number,
    required: false,
    default: 0,
  },
  isLeftTime: {
    type: Boolean,
    required: false,
    default: false,
  },
  showType: {
    type: String,
    default: '',
  },
  fontSize: {
    type: String,
    default: '12px',
  },
  showEndTips: {
    type: Boolean,
    default: false,
  },
  // 倒计时归零时，不隐藏倒计时
  notHide: {
    type: Boolean,
    default: false,
  },
  // 倒计时归零时，不刷新页面
  notReload: {
    type: Boolean,
    default: false,
  },
  hideTip: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    required: true,
  },
})

const diffDays = ref<any>('')
const diffHours = ref<any>('')
const diffMinutes = ref<any>('')
const diffSeconds = ref<any>('')
const showCountDown = ref(true)

const daysComputed = computed(() => {
  if (diffDays.value > 1) {
    return `${diffDays.value} ${props.language.SHEIN_KEY_PC_17212 || 'Days'}`
  } else if (diffDays.value == 1) {
    return `${diffDays.value} ${props.language.SHEIN_KEY_PC_15173 || 'Day'}`
  } else {
    return ''
  }
})

let timer: any = null
const setTimer = () => {
  if (timer) {
    return
  } else {
    timer = setInterval(() => {
      if (diffSeconds.value - 1 >= 0) {
        diffSeconds.value = diffSeconds.value - 1
      } else {
        if (diffMinutes.value - 1 >= 0) {
          diffMinutes.value = diffMinutes.value - 1
          diffSeconds.value = 59
        } else {
          if (diffHours.value - 1 >= 0) {
            diffHours.value = diffHours.value - 1
            diffMinutes.value = 59
            diffSeconds.value = 59
          } else {
            if (diffDays.value - 1 >= 0) {
              diffDays.value = diffDays.value - 1
              diffHours.value = 24
              diffMinutes.value = 59
              diffSeconds.value = 59
            } else {
              clearInterval(timer)
              if (!props.notHide) showCountDown.value = false
              if (!props.notReload) window.location.reload()
            }
          }
        }
      }
    }, 1000)
  }
}
const init = async () => {
  const countDownData = props.timeStamp ? countDownTime({ endTimestamp: props.isLeftTime ? (Date.now() + (props.timeStamp * 1000)) : props.timeStamp }) : typeof props.countDown == 'object' ? props.countDown : countDownTime({ endTimestamp: props.countDown })
  diffDays.value = ['inline-promotion', 'promotion-tab'].includes(props.showType) ? 0 : +countDownData.diffDays
  diffHours.value = ['inline-promotion', 'promotion-tab'].includes(props.showType) ? +countDownData.diffHours + (+countDownData.diffDays * 24 ) : +countDownData.diffHours
  diffMinutes.value = +countDownData.diffMinutes
  diffSeconds.value = +countDownData.diffSeconds
  setTimer()
  if(countDownData.diffTime <= 0) { // init found count down is <= 0, do not render countdown
    showCountDown.value = false
  }
}
const twoNums = (val) => {
  if (typeof val === 'number' && !isNaN(val)) {
    return val < 10 ? `0${val}` : `${val}`
  } else {
    return ''
  }
}

onMounted(() => {
  init()
})
onBeforeUnmount(() => {
  if (timer.value) clearInterval(timer.value)
})

/**
 * 计算倒计时
 */
function countDownTime({ endTimestamp = 0 }) {
  let end = endTimestamp || 0
  if(end){
    end = (end + '').length >= 13 ? end : end * 1000
  }
  const now = (new Date()).getTime()
  const diffTime = end - now
  const diffDays = Math.floor(diffTime / 1000 / 60 / 60 / 24) // 相差天数
  const diffHours = Math.floor(diffTime / 1000 / 60 / 60 - diffDays * 24) // 相差小时
  const diffMinutes = Math.floor(diffTime / 1000 / 60 - diffDays * 24 * 60 - diffHours * 60) // 相差分钟
  const diffSeconds = Math.floor(diffTime / 1000 - diffDays * 24 * 60 * 60 - diffHours * 60 * 60 - diffMinutes * 60) // 相差秒数

  return {
    diffTime,
    diffDays,
    diffHours,
    diffMinutes,
    diffSeconds,
  }
}
</script>

<style lang="less">
.bsc-mini-cart-count-down__activity-tips {
  color: #222222;
}

.bsc-mini-cart-count-down {
  margin-top: 6px;
  font-weight: normal;
  color: #C44A01;
  font-size: 12px;
  .bsc-mini-cart-count-down__content {
    display: inline-block;
    font-weight: normal;
    color: #C44A01;
    /* rtl:begin:ignore */
    direction: ltr;
    span{
      display: inline;
      font-weight: normal;
      color: #C44A01;
    }
  }
  &.bsc-mini-cart-count-down_promotion {
    .bsc-mini-cart-count-down__content {
      color: #222222;
      span {
        color: #222222;
        background: #fff;
        padding: 1px 2px;
      }
    }
  }
  &.bsc-mini-cart-count-down_active {
    .bsc-mini-cart-count-down__content {
      font-weight: bold;
      span {
        font-weight: bold;
      }
    }
  }
}
</style>
