import type { AS_HeaderCart } from '../../../types'

const standard: AS_HeaderCart.PCAnalysisFuncs = {
  'expose_cart_entrance.comp_header-cart': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_hover_cart_entrance.comp_header-cart': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_cart_entrance.comp_header-cart': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_cart_entrance_popover.comp_header-cart': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_cart_entrance_leftpopover.comp_header-cart': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
}

export default standard
