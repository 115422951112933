<template>
  <div
    v-if="(!isHide && !isEnd)"
    :style="{ height: `${getBannerHeight()}px` }"
  >
    <LongBannerCountdown
      :prop-data="propData"
      :index="index"
      :count-timer="countTimer"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import { CountDown } from '@shein/common-function'
import { commonProps } from '../../common/common-props.js'
import LongBannerCountdown from './LongBannerCountdown.vue'


const props = defineProps({
  ...commonProps,
})

const getBannerHeight = inject('getBannerHeight')

const isHide = ref(false)
const isEnd = ref(false)
const countTime = ref(0)
const curTime = ref(Date.now())


const countTimer = ref(
  new CountDown({
    endFunc: () => isEnd.value = true,
  }),
)


const handleLoopCountdown = ({ startTime, endTime, loopTime }) => {
  if (curTime.value > startTime && curTime.value < endTime) {
    const overTime = (curTime.value - startTime) / 1000
    const restTime = (endTime - curTime.value) / 1000
    const remainTime = ((endTime - startTime) / 1000) % (loopTime * 3600)
    if (overTime % (loopTime * 3600) && restTime > remainTime) {
      countTime.value = loopTime * 3600 - (overTime % (loopTime * 3600))
    } else {
      countTime.value = restTime
    }
  } else if (curTime.value < startTime) {
    countTime.value = (new Date(startTime).getTime() - curTime.value) / 1000
    isHide.value = true
  } else if (endTime < curTime.value) {
    isHide.value = true
  }
}

const handleNoLoopCountdown = ({ startTime, endTime }) => {
  if (
    new Date(startTime).getTime() < curTime.value &&
    new Date(endTime).getTime() > curTime.value
  ) {
    countTime.value = (new Date(endTime).getTime() - curTime.value) / 1000
  } else if (new Date(startTime).getTime() > curTime.value) {
    countTime.value = (new Date(startTime).getTime() - curTime.value) / 1000
    isHide.value = true
  } else if (new Date(endTime).getTime() < curTime.value) {
    isHide.value = true
  }
}

const initCountdown = () => {
  const { props: propDataProps = {} } = props.propData || {}
  const {
    countdownRange = {},
    countdownType = 1,
    loopTime,
  } = propDataProps.metaData || {}
  const {
    startTime,
    endTime,
  } = countdownRange || {}
  if (countdownType == 1) {
    // 连续循环倒计时
    handleLoopCountdown({ startTime: +startTime, endTime: +endTime, loopTime: +loopTime })
  } else if (countdownType == 3) {
    // 非循环倒计时
    handleNoLoopCountdown({ startTime: +startTime, endTime: +endTime })
  }
  countTimer.value.start({ seconds: countTime.value, dayMode: true })
}

onMounted(() => {
  initCountdown()
})

</script>

