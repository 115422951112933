/**
 * 替换地址问号前特殊字符
 */
export function replaceHrefSpeCha(href){
  if(typeof href != 'string') return href
  const hrefOrigins = href && href.match(/^http(s)?:\/\/(.*?)\//g)
  if (hrefOrigins) {
    const hrefOrigin = hrefOrigins[0]
    // eslint-disable-next-line no-useless-escape
    if (hrefOrigin && !hrefOrigin.match('\.shein\.') && !hrefOrigin.match('\.romwe\.')){
      return href
    }
  }
  //搜索排除
  if(/pdsearch/.test(href)) return href
  const pathBegin = href.indexOf('://') > 0 ? href.indexOf('://') + 2 : 0
  const seachBegin = href.indexOf('?', pathBegin) > 0 ? href.indexOf('?', pathBegin) : href.length
  const protocol = href.substring(0, pathBegin)
  let pathName = href.substring(pathBegin, seachBegin)
  const search = href.substring(seachBegin, href.length)
  pathName = pathName.replace(/[><#@$\s'"%+&]|[\u4e00-\u9fa5]/g, '-')
  href = (protocol + pathName + search).replace(/-{2,}/g, '-')
  return href
}

/**
 * 获取商品URL
 */
export function getGoodsUrl(name, id) {
  //拼接商品详情路由
  name = replaceHrefSpeCha(name)
  name = (name == '-' || ! name) ? 'shein-product' : name
  const url = (
    '/' +
          name
            .replace('/', '-')
            .split(' ')
            .join('-') +
          '-p-' +
          id +
          '.html'
  )
  return replaceHrefSpeCha(url)
}

export const UtilCache = {
  scLinkMap: {
    // scId: 'www.shein.com/p-sc-scId'
  },
  get(module, key) {
    if (this[module]) {
      return this[module][key]
    } else {
      console.error('Module does not exist.')
    }
  },
  set(module, key, value) {
    if (this[module]) {
      this[module][key] = value
    } else {
      console.error('Module does not exist.')
    }
  },
}

export function getMaxZIndex() {
  let maxZIndex = 0
  const allElements = document.getElementsByTagName('*')
  Array.from(allElements).forEach((element) => {
    const zIndex = Number(window.getComputedStyle(element).zIndex)
    if (!isNaN(zIndex) && zIndex > maxZIndex) {
      maxZIndex = zIndex
    }
  })
  return maxZIndex
}
