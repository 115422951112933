<template>
  <div class="bsc-base-img">
    <a
      :style="getImgPlaceholder()"
      class="bsc-base-img__href"
      role="link"
      :tabindex="0"
      :aria-label="ada"
      @click.prevent="clickImg"
    >
      <img
        :style="{ objectPosition: position }"
        class="bsc-base-img__inner fsp-element bsc-base-img__cover"
        :src="baseCutImg"
        :alt="alt"
      />
    </a>
  </div>
</template>

<script setup>
import { computed, onMounted, inject } from 'vue'

const props = defineProps({
  imgSrc: {
      type: String,
      default: '',
    },
    // 裁剪定位
    position: {
      type: String, // 同 object-position
      default: 'initial',
    },
    // 裁剪方式
    fit: {
      type: String, // 'none' | 'contain' | 'cover' | 'fill' | 'scale-down' | 'initial' | 'inherit'
      default: 'initial',
    },
    // 占位图使用方式参考：
    // wiki.pageId=611059170
    placeholder: {
      type: Object,
      default: () => ({
        width: 500,
        height: 500,
        query: '',
      }),
    },
    // 高/宽比率
    ratio: {
      type: [Number, String],
      default: 0,
    },
    ada: {
      type: String,
      default: 'Photo',
    },
    alt: {
      type: String,
      default: '',
    },
    brand: {
      type: String,
      default: 'shein',
    },
    imgDesignWidth: {
      type: [Number, String],
      default: 0,
    },
    imgDataExp: {
      type: Object,
      default: () => ({}),
    },
    firstScreen: {
      type: Boolean,
      default: false,
    },
})


const emit = defineEmits(['click'])

const cutImg = inject('cutImg', (url) => url)
const baseImageMounted = inject('baseImageMounted', () => {})

const baseCutImg = computed(() => {
  // 如果是 webp,不需要裁剪
  if (props.imgSrc.includes('.webp')) {
    return props.imgSrc
  }
  if (!cutImg) {
    return props.imgSrc.replace(/^https?:/, '')
  }
  return cutImg(props.imgSrc, props.imgDesignWidth, props.imgDataExp)
})


const clickImg = () => {
  emit('click')
}

const getImgPlaceholder = () => {
  const { height, width } = props.placeholder
  return {
    paddingBottom: ((height / width) * 100).toFixed(2) + '%',
  }
}

onMounted(() => {
  baseImageMounted()
})
</script>

<style lang="less">
.bsc-base-img {
  width: 100%;
  position: relative;
  overflow: hidden;
  &__href {
    width: 100%;
    display: block;
  }

  &__inner {
    width: 100%;
    height: 100%;
    vertical-align: top;
    position: absolute;
  }
  &__cover {
    object-fit: cover;
  }
}
</style>
