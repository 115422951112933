<template>
  <div
    v-for="item in cates"
    :key="item.cateId"
    class="bs-cate-type"
    :class="{ 'is-two': showTitleIcon }">
    <div class="bs-cate-type_title">
      <Icon
        v-if="showTitleIcon"
        name="sui_icon_sbc_categories_16px"
        color="#c44a01"
        size="16px" />
      <h6
        v-tap="{
          id: 'click_navigation_menu.comp_laptop',
          data: { tabNav,navCate, isAllHover, thirdItem:item, isslidebar: isAllHover, },
        }"
        v-expose="{
          id: 'expose_navigation_menu.comp_laptop',
          data: { tabNav,navCate, isAllHover,thirdItem:item, isslidebar: isAllHover, },
          code: `third_${Number(isAllHover)}`,
          delayReady: 300,
        }"
        class="bs-cate-type_title-text"
        :da-eid="`nav_third_${Number(isAllHover)}_${navCate?.index}_${item.index}`"
      >
        {{ item.categoryLanguage }}
      </h6>
    </div>
    <div
      v-if="item.children?.length"
      class="bs-cate-type_content">
      <a
        v-for="_ in item.children"
        :key="`${item.cateId}_${_.index}`"
        v-tap="{
          id: 'click_navigation_menu.comp_laptop',
          data: { tabNav,navCate, isAllHover, thirdItem:item, fourthItem:_, isslidebar: isAllHover, },
        }"
        v-expose="{
          id: 'expose_navigation_menu.comp_laptop',
          data: { tabNav,navCate, isAllHover,thirdItem:item,fourthItem:_, isslidebar: isAllHover, },
          code: `fourth_${Number(isAllHover)}`,
          delayReady: 300,
        }"
        v-customlink="{ beforeHook:genHook(_), getJumpFlag, saInfo:_.saInfo }"
        class="bs-cate-type_img-link"
        :da-eid="`nav_fourth_${Number(isAllHover)}_${navCate?.index}_${item.index}_${_.index}`"
        :href="getLink(_, tabNav)"
      >
        <span :class="['bs-cate-type_img', _.autoRecommend ? 'border' : '']">
          <img
            class="lazyload_img"
            :src="genCutImg(_.target || _.cover?.src)"
            :alt="_.categoryLanguage"
          />
        </span>
        <span class="bs-cate-type_text">{{ handleNbsp(_.categoryLanguage) }}</span>
      </a>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, inject } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { getCutImg } from '../../../common/hooks/useCutImg'
import { customLinkDirective } from './directives'
// import { mergeQueryString } from '@shein/common-function'
import { getContentUrl } from '../../../common/analysisSource/sources/standard'

defineProps({
  showTitleIcon: {
    type: Boolean,
    default: false,
  },
  cates: {
    type: Array,
    default: () => [],
  },
  navCate:{
    type: Object,
    default: () => ({}),
  },
  tabNav:{
    type: Object,
    default: () => ({}),
  },
  isAllHover:{
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(['clickCate'])
const vCustomlink = customLinkDirective()
const { vTap, vExpose } = inject('analysisInstance')
const getJumpFlag = inject('getJumpFlag')

const handleNbsp = str => {
  let replaceStr = encodeURI(str)
  replaceStr = replaceStr.replace(/%C2%A0/g, '%20')
  replaceStr = decodeURI(replaceStr)
  return replaceStr
}

const cutImg = getCutImg()

function genCutImg(imgUrl) {
  if (typeof cutImg === 'function') {
    return cutImg(imgUrl, 200, 'unCut=0#max=200')
  } else {
    return imgUrl
  }
}

// 缓存已加载的图片
// function loadImg(e,item){
//   const targetSrc = e.target?.src || ''
//   if(!targetSrc.endsWith(defaultImgIcon)){
//     item.lazyed = true
//     item.lazyImg = targetSrc
//   }
// }

function genHook(item){
  return () => emits('clickCate',item)
}
// function beforeHook(){
//   emits('clickCate')
// }
function getLink(item, tabNav){
  let {second_content_list: url} = getContentUrl(item.saInfo || {},tabNav)
  return url
}
</script>
<style lang="less">
.bs-cate-type {
  &.is-two {
    .bs-cate-type_img-link {
      flex: 0 0 calc(100% / 3);
    }
    .bs-cate-type_title-text {
      color: #c44a01;
    }
  }
  &_title {
    display: flex;
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    padding-top: 20px;
    &-text {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #000;
      margin: 0 5px;
    }
  }
  &_content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &_img-link {
    text-align: center;
    width: 82px;
    vertical-align: top;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    padding: 5px 2px 5px 0;
    &:focus,
    &:hover {
      text-decoration: none;
      .bs-cate-type_img {
        transform: scale(1.08);
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
      }
    }
  }
  &_img {
    display: inline-block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    &.border {
      border: 0.5px solid #e4e4e4;
    }
  }
  .lazyload_img{
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #f5f5f5;
    object-fit: cover;
    object-position: center;
    background-size: 100%;
    background-position: center;
  }
  &_text {
    margin-top: 4px;
    display: inline-block;
    width: 100%;
    color: #000;
    hyphens: auto;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
</style>
