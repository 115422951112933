<template>
  <TopNavSke v-if="showSkeleton && cateNavs.length === 0" />
  <nav
    v-else
    class="bs-nav"
    @mouseleave="emits('mouseleave')">
    <div
      v-if="cateNavs?.length"
      class="bs-nav__cate first-cate"
      :class="{ hover: isAllHover }"
      @mouseenter="handleMouseEnter(null)"
    >
      <span>
        {{ allCateText }}
      </span>
      <DownIcon class="bs-cate_icon-down" />
    </div>
    <div
      ref="topNavRef"
      class="bs-nav__content">
      <div
        class="bs-nav__content-inner"
        :class="{is_ar:cssRight}">
        <a
          v-for="item in cateNavs"
          :key="item.id"
          v-customlink="{ beforeHook: genHook(item), getJumpFlag, saInfo:item.saInfo, id:item.id }"
          v-tap="{id:'click_navigation_menu.comp_laptop',data:{ isAllHover, navCate:item, isslidebar:false }}"
          v-expose="{id:'expose_navigation_menu.comp_laptop',data:{ isAllHover, navCate:item, isslidebar:false, code:'second_0' }}"
          class="bs-nav__cate-link"
          :class="{ active: item.id == activeNavId,hover:hoverEntryNav == item.id }"
          :href="getLink(item)"
          @mouseenter="handleMouseEnter(item)"
        >
          {{ item.categoryLanguage }}
        </a>
        <span class="bs-nav__arr-ske"></span>
      </div>
    </div>
    <div
      v-show="canNext || canPre"
      class="bs-nav__arrow">
      <ArrIcon
        type="left"
        :isRotate="cssRight"
        :disabled="!canPre"
        @click="handleScroll('pre')" />
      <ArrIcon
        type="right"
        :isRotate="cssRight"
        :disabled="!canNext"
        @click="handleScroll('next')" />
    </div>
  </nav>
</template>
<script setup>
import { ref, defineProps, defineEmits, defineExpose, onMounted, inject, onUnmounted, watch, nextTick } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import ArrIcon from './ArrIcon'
import DownIcon from './DownIcon'
import TopNavSke from './TopNavSke.vue'
import { debounce, throttle } from '@shein/common-function'
import { getContentUrl } from '../../../common/analysisSource/sources/standard'
import { customLinkDirective } from './directives'

const {vTap, vExpose} = inject('analysisInstance')
const getJumpFlag = inject('getJumpFlag')

const emits = defineEmits(['mouseenter', 'mouseenterpanel', 'mouseleave', 'navClick'])
const props = defineProps({
  cateNavs: {
    type: Array,
    default: () => [],
  },
  navCate:{
    type: Object,
    default: () => ({}),
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  isAllHover:{
    type:Boolean,
    default: false,
  },
  hoverEntryNav:{
    type: String,
    default: '',
  },
  activeNavId: {
    type: String,
    default: '',
  },
  allCateText: {
    type: String,
    default: 'Categories ',
  },
  showSkeleton:{
    type: Boolean,
    default: false,
  },
})
const vCustomlink = customLinkDirective()

const appConfigs = useAppConfigs()
const { cssRight = false } = appConfigs.$envs || {}
const scrollUnit = cssRight ? -1 : 1
const topNavRef = ref(null)
const canNext = ref(true)
const canPre = ref(false)
let navItemWidths = []

function genHook(params) {
  return () => {
    emits('navClick',params)
  }
}
function getLink(item){
  let {second_content_list: url} = getContentUrl(item.saInfo || {})
  return url
}

function handleMouseEnter(item){
  emits('mouseenter', item, 'topNav')
  emits('mouseenterpanel')
}


function scrollTo(left, behavior = 'smooth') {
  if (topNavRef.value) {
    topNavRef.value.scrollTo({
      left,
      behavior,
    })
  }
}

function scrollToView(id, left){
  if (!topNavRef.value) return
  let scrollLeft = left
  const index = props.cateNavs.findIndex(item => item.id === id) - 2
  scrollLeft = navItemWidths[index] ? navItemWidths[index].offsetLeft : 0
  scrollTo(scrollUnit * scrollLeft, 'instant')
}

function handleScroll(type, behavior = 'smooth'){
  let offsetLeft = 0
  if(type === 'next'){
    const wrapWidth = topNavRef.value.getBoundingClientRect().width
    const innerWidth = topNavRef.value.children[0].getBoundingClientRect().width
    offsetLeft = innerWidth - wrapWidth
  }
  scrollTo( scrollUnit * offsetLeft, behavior)
}
// 横向滑动处理事件
const handleScrollX = event => {
  event.preventDefault()
  if (event.deltaX < 0) return // 防止触控板会触发两次
  if (event.deltaY == 0) return
  let h = true
  if (event.wheelDelta) {
    h = event.wheelDelta > 0
  } else {
    h = event.detail <= 0
  }
  const distance = scrollUnit * event.deltaY
  if (h) {
    if (!canPre.value) return
    scrollTo(topNavRef.value?.scrollLeft + distance || 0)
  } else {
    if (!canNext.value) return
    scrollTo(topNavRef.value?.scrollLeft + distance || 0)
  }
}

const calcArrVisible = throttle({
  func(){
    if(!topNavRef.value) return
    const wrapWidth = topNavRef.value.getBoundingClientRect().width
    const innerWidth = topNavRef.value.children[0].getBoundingClientRect().width
    const scrollLeft = scrollUnit * topNavRef.value.scrollLeft

    if(wrapWidth > innerWidth){
      canNext.value = false
      canPre.value = false
    }else{
      canPre.value = scrollLeft > 0
      canNext.value = scrollLeft + wrapWidth <= innerWidth - 2
    }
  },
  wait: 300,
  options: {
    leading: false,
  },
})

watch(() => props.cateNavs, () => {
  nextTick(() => {
    topNavRef.value?.removeEventListener('scroll', calcArrVisible)
    topNavRef.value?.addEventListener('scroll', calcArrVisible)
  })
  calcArrVisible()
})

onMounted(() => {
  if (topNavRef.value) {
    topNavRef.value.addEventListener(
      'wheel',
      debounce({
        func: handleScrollX,
        wait: 50,
      }),
    )
    topNavRef.value?.addEventListener('scroll', calcArrVisible)
    const children = topNavRef.value.children?.[0]?.children
    const itemWidths = []
    let offsetLeft = 0
    if(children && children.length > 0){
      for (let i = 0; i < children.length; i++) {
        const width = children[i].getBoundingClientRect().width
        itemWidths.push({width, offsetLeft})
        offsetLeft += width
      }
    }
    navItemWidths = itemWidths

    // window.scrollNavTo = scrollTo
    calcArrVisible()
  }
  window.addEventListener('resize',calcArrVisible)
})
onUnmounted(() => {
  window.removeEventListener('resize',calcArrVisible)
  topNavRef.value?.removeEventListener('scroll', calcArrVisible)
})

function getScrollLeft(){
  if(topNavRef.value){
    return topNavRef.value.scrollLeft
    // const wrapWidth = topNavRef.value.getBoundingClientRect().width
    // const innerWidth = topNavRef.value.children[0].getBoundingClientRect().width
    // const offsetLeft = Math.round(innerWidth - wrapWidth)
    // return offsetLeft - left < 100 ? offsetLeft : left
    // return topNavRef.value.scrollLeft
  }
  return 0
}

function getLayoutTop(){
  if(topNavRef.value){
    const rect = topNavRef.value.getBoundingClientRect()
    return `${rect.top + rect.height + 1}px`
  }
  return 0
}

/**
 * @desc 滚动条显示后横向滑动位置偏移问题修改
 */
function resetNavPosition(barWidth){
  if(canNext.value) return
  // 滚动偏移在body滚动条隐藏的情况下，需要异步执行滚动，否则会不生效
  nextTick(() => {
    scrollTo(topNavRef.value?.scrollLeft + scrollUnit * barWidth, 'instant')
  })
}

defineExpose({
  scrollTo,
  scrollToView,
  getScrollLeft,
  getLayoutTop,
  resetNavPosition,
})
</script>
<style lang="less">
.bs-nav {
  padding: 0 45px;
  position: relative;
  z-index: 12;
  //   padding-right: 100px;
  height: 40px;
  overflow: hidden;
  display: flex;
  border-bottom: 0.5px solid var(--sui-color-gray-weak-1, #e5e5e5);
  background: var(--sui-color-white, #fff);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
  // border: 1px solid red;
  // &__cate {
  //   border: 1px solid red;
  // }
  &__content {
    flex: 1;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    // border: 1px solid red;
    display: flex;
    position: relative;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    &-inner {
      // border: 1px solid black;
      white-space: nowrap;
      display: flex;
      position: relative;
      // &.is_ar {
      //   padding-right: 0;
      //   padding-left: calc(75px - var(--scroll-bar-widht));
      // }
    }
  }
  &__arr-ske{
    width: 50px;
    // margin-left: var(--scroll-bar-width);
  }

  .bs-nav__cate,
  &__cate-link {
    display: inline-block;
    height: 100%;
    padding: 0 10px;
    line-height: 40px;
    color: #000;
    font-weight: 400;
    font-size: 13px;
    border-bottom: 2px solid transparent;
    text-decoration: none;
    cursor: pointer;
    &.active {
      border-color: #222;
      color: #000;
    }
    &:focus,
    &.hover,
    &:hover {
      text-decoration: none;
      color: #000;
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
  .first-cate {
    .bs-cate_icon-down {
      transition: 0.3s;
      margin-top: -2px;
    }
    &.hover,
    &:hover {
      .bs-cate_icon-down {
        transform: rotate(180deg);
      }
    }
  }
  &__arrow {
    box-shadow: rgba(0, 0, 0, 0.08) -2px 0px 3px 0px;
    height: 100%;
    line-height: 1em;
    width: 50px;
    text-align: center;
    white-space: nowrap;
    background: var(--sui-color-white, #fff);

    position: absolute;
    top: 0;
    right: 45px;
    // display: none;
    // border: 1px solid red;
  }
}
</style>
