<template>
  <div
    class="bs-cate-dropdown"
    :class="{ show: showWrap }">
    <div
      class="bs-cate-content"
      :class="{ show: showContent }"
      @mouseleave="emits('mouseleave')"
      @mouseenter="emits('mouseenterpanel')">
      <div
        ref="slidecateRef"
        class="bs-cate_one bs-cate_scroll">
        <ul class="bs-cate_one-container">
          <li
            v-for="item in cateNavs"
            :key="item.id"
            v-tap="{ id: 'click_navigation_menu.comp_laptop', data: { isAllHover,tabNav, navCate:item, isslidebar: true } }"
            v-expose="{ id: 'expose_navigation_menu.comp_laptop', data: { isAllHover,tabNav, navCate:item, isslidebar: true, code:'second_1' } }"
            class="bs-cate_one-item"
            :class="{ hover: navCate.id === item.id }"
            @mouseenter="emits('mouseenter', item)"
          >
            <span class="bs-cate-text">{{ item.categoryLanguage }}</span>
            <Icon
              name="sui_icon_more_right_16px_2"
              color="rgba(34,34,34,.3)"
              :is-rotate="cssRight" />
          </li>
        </ul>
      </div>
      <div class="bs-cate-right">
        <div class="split-line"></div>
        <div class="bs-cate_two bs-cate_scroll">
          <CateSkeleton v-if="rightLoading" />
          <CateType
            v-else
            :showTitleIcon="true"
            :cates="rightCate.two"
            :navCate="navCate"
            :tabNav="tabNav"
            :isAllHover="isAllHover"
            @clickCate="(item) => emits('clickCate',item)" />
        </div>
        <div class="split-line"></div>
        <div class="bs-cate_three bs-cate_scroll">
          <CateSkeleton v-if="rightLoading" />
          <CateType
            v-else
            :cates="rightCate.three"
            :navCate="navCate"
            :tabNav="tabNav"
            :isAllHover="isAllHover"
            @clickCate="(item) => emits('clickCate',item)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, defineExpose, inject, computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import CateType from './CateType'
import CateSkeleton from './CateSkeleton'

const {vTap, vExpose} = inject('analysisInstance')

const props = defineProps({
  cateNavs: {
    type: Array,
    default: () => [],
  },
  navCate:{
    type: Object,
    default: () => ({}),
  },
  tabNav:{
    type: Object,
    default: () => ({}),
  },
  rightCate: {
    type: Object,
    default: () => ({}),
  },
  isAllHover:{
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: true,
  },
})

const emits = defineEmits(['mouseenter', 'mouseenterpanel', 'mouseleave', 'clickCate'])
const appConfigs = useAppConfigs()
const { cssRight = false } = appConfigs.$envs || {}

const showWrap = ref(false)
const showContent = ref(false)
const slidecateRef = ref(null)

const rightLoading = computed(() => {
  const { loading, rightCate } = props
  const hasTwoData = rightCate.two?.length
  return loading || !hasTwoData
})
function showCate() {
  showWrap.value = true
  showContent.value = true
}

function closeCate() {
  showContent.value = false
  showWrap.value = false
}

function scrollTo(item) {
  if (slidecateRef.value) {
    const top = (item.index + 1) * 38 - 2.5 * 38 + 20
    // console.log('scroll top',Math.max(top,0));
    slidecateRef.value.scrollTo({
      top: top,
      behavior: 'smooth',
    })
  }
}

// window.showCate = showCate
// window.closeCate = closeCate
// window.scrollTo = scrollTo
defineExpose({ showCate, closeCate, scrollTo })
</script>
<style lang="less">
.bs-cate-dropdown {
  position: fixed;
  top: var(--layout-top);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 399;
  overflow: hidden;
  display: none;
  &.show {
    display: block;
  }
  .bs-cate-content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    min-height: 200px;
    max-height: 66vh;
    background-color: #fff;
    // transition: 0.3s;
    // transform: translate3d(0, -100%, 0);
    padding: 0 45px;
    display: flex;
    // border: 1px solid red;
    // &.show {
    //   transform: translate3d(0, 0, 0);
    // }
  }
  .split-line {
    width: 1px;
    height: calc(100% - 40px);
    margin-top: 20px;
    background-color: #e5e5e5;
  }
  .bs-cate-right {
    flex: 1;
    display: flex;
    position: relative;
  }
  .bs-cate_scroll {
    position: relative;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 5px;
    }
  }
  .bs-cate_one:hover,
  .bs-cate_two:hover,
  .bs-cate_three:hover {
    &.bs-cate_scroll::-webkit-scrollbar-thumb {
      background-color: #a8a8a8;
    }
  }
  .bs-cate {
    &_one {
      padding-top: 20px;
      flex-shrink: 0;
      &-container {
        width: 232px;
      }
      &-item {
        height: 38px;
        line-height: 38px;
        padding: 2px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .bs-cate-text {
          flex: 1;
          overflow: hidden;
          word-wrap: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        &.hover,
        &:hover {
          background-color: #e5e5e5;
        }
      }
    }

    &_two {
      width: 35%;
      min-width: 295px;
      height: 100%;
      // padding: 0 20px;
      .bs-cate-type_img-link {
        flex: 0 0 calc(100% / 3);
      }
      .bs-cate-type_title-text {
        color: #c44a01;
      }
    }
    &_three {
      height: 100%;
      // padding: 0 20px;
      flex: 1;
    }
  }
}
</style>
