<template>
  <span
    class="bs-nav-icon"
    :class="{disabled}">
    <svg
      style="width: auto; height: 1em"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        :d="path"
        fill="currentColor"
      />
    </svg>
  </span>
</template>
<script setup>
import { defineProps, computed } from 'vue'
const props = defineProps({
  type: {
    type: String,
    default: 'left',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isRotate: {
    type: Boolean,
    default: false,
  },
})

const leftPath = 'M8.04283 2.16797L9.45705 3.58218L6.03994 6.99987L9.45705 10.4175L8.04283 11.8318L3.21094 6.99987L8.04283 2.16797Z'
const rightPath = 'M4.54285 3.58218L5.95706 2.16797L10.789 6.99987L5.95706 11.8318L4.54285 10.4175L7.95995 6.99987L4.54285 3.58218Z'

const path = computed(() => {
  const {isRotate,type} = props
  if(isRotate){
    return type !== 'left' ? leftPath : rightPath
  }
  return type === 'left' ? leftPath : rightPath
})
</script>
<style lang="less">
.bs-nav-icon{
    vertical-align: middle;
    display: inline-block;
    font-size: 14px;
    height: 100%;
    line-height: 40px;
    margin: 0 5px;
    cursor: pointer;
    &.disabled{
      color: rgba(0,0,0,.15);
      cursor:auto;
    }
}
</style>
