<template>
  <SDialog
    :visible="showLogoutRememberModal"
    :append-to-body="true"
    :show-close="true"
    :immediately-render="true"
    :close-on-click-modal="false"
    @close-from-icon="handleCloseRememberModal"
  >
    <p class="bsc-remember__logout-text">
      {{ langText?.SHEIN_KEY_PC_25876 }}
    </p>
    <template #footer>
      <SButtonGroup hor>
        <SButtonGroupItem @click="handleLogout">
          {{ langText?.SHEIN_KEY_PC_25872 }}
        </SButtonGroupItem>
        <SButtonGroupItem
          :type="['primary']"
          @click="handleRememberAccount"
        >
          {{ langText?.SHEIN_KEY_PC_25871 }}
        </SButtonGroupItem>
      </SButtonGroup>
    </template>
  </SDialog>
</template>

<script setup>
import { ref, defineExpose, inject } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog/laptop'
import { SButtonGroup } from '@shein-aidc/sui-button-group/laptop'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/laptop'
import { removeAuthUserInfo } from './common/auth.js'
import { setGoogleOneTapCatch } from './common/login.js'
import { useAccountAnalysis} from './common/useAnalysis.js'

defineProps({
  langText: {
    type: Object,
    default: () => ({}),
  },
})

const triggerNotice = inject('triggerNotice')

const { exposeRememberAccountPop, clickRememberAccountPopYes, clickRememberAccountPopNo } = useAccountAnalysis(triggerNotice)

const showLogoutRememberModal = ref(false)

const handleShowRememberModal = async () => {
  showLogoutRememberModal.value = true
  exposeRememberAccountPop()
}

const handleCloseRememberModal = () => {
  showLogoutRememberModal.value = false
}

const handleRememberAccount = () => {
  try {
    clickRememberAccountPopYes()
    setGoogleOneTapCatch()
    removeAuthUserInfo()
    const link = '/auth/remember/logout'
    window.location = link
    showLogoutRememberModal.value = false
  } catch (error) {
    console.error(error + '')
    handleLogout()
  }
}

const handleLogout = () => {
  clickRememberAccountPopNo()
  setGoogleOneTapCatch()
  removeAuthUserInfo()
  const link = '/user/auth/logout'
  showLogoutRememberModal.value = false
  window.location.href = link.indexOf('?') > -1 ? `${link}&from=navTop` : `${link}?from=navTop`
}

defineExpose({
  handleShowRememberModal,
})
</script>

<style lang="less" scoped>
.bsc-remember__logout-text{
  font-size: 16px;
  font-weight: 700;
  color: #222;
  text-align: center;
}
</style>
