<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.70398 1.5V3.96006L11.2846 4.80373V9.72587H9.95608C9.84718 10.1792 9.43911 10.5161 8.95234 10.5161C8.46558 10.5161 8.0575 10.1792 7.9486 9.72587H7.70398V9.72581H4.51095C4.40207 10.1792 3.99398 10.5161 3.50719 10.5161C3.02041 10.5161 2.61232 10.1792 2.50344 9.72581H0.994303V7.64674H1.9943L1.99401 8.72567H7.70368V2.5H1.99401V3.82233L4.07495 3.82258V4.82258H0.994303V1.5H8.70398ZM8.70398 5.01212V8.72571H10.2844V5.52871L8.70398 5.01212ZM2.65559 6.62907V5.62907H0.333008V6.62907H2.65559Z"
      fill="white"
    />
  </svg>
</template>
