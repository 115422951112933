<template>
  <!-- 渲染多个（单个？）不同业务组件 -->
  <div
    v-if="list.length"
    :style="{ height: `${getBannerHeight()}px` }"
  >
    <div
      v-for="(data, index) in list"
      :key="`${data.id}_${index}`"
      style="height: 100%;"
    >
      <component
        :is="components[data.__comp_name]"
        :index="index"
        :content="content"
        :prop-data="data"
        :data-component-name="data.props && data.props.name"
      >
        <template
          v-for="(_, name) in $slots"
          #[name]>
          <slot :name="name"></slot>
        </template>
      </component>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import components from './common/comp.js'
import { compMapping } from './common/comp-map.js'
import { commonProps } from './common/common-props.js'

const props = defineProps({
  ...commonProps,
})

const getBannerHeight = inject('getBannerHeight')

const list = computed(() => {
  return props.content.filter((item) => {
      if (!compMapping(item)) return false
      item.__comp_name = compMapping(item)

      return true
    })
})
</script>
