import { getQueryString } from '@shein/common-function'

export function isObject(value: any): value is object {
  return value !== null && typeof value === 'object'
}

function searchRedirFun(lang) {
  const searchRedirectWords = getQueryString({ key: 'search_redir' }) && getQueryString({ key: 'search_words' })
  if (searchRedirectWords) {
    const keywords = searchRedirectWords
    let words = localStorage.getItem(`${lang}SearchHisotryWords`) as any
    try {
      words = JSON.parse(words) || []
    } catch (error) {
      words = []
    }
    words = words.filter(item => (isObject(item) ? keywords != (item as any).keywords : keywords != item))
    words.unshift(keywords)
    localStorage.setItem(`${lang}SearchHisotryWords`, JSON.stringify(words.slice(0, 10)))
  }
}

function searchHistoryFun(lang) {
  if (typeof (window as any).gbRawData === 'undefined') return
  // eslint-disable-next-line no-undef
  const { cat_info, searchKeywords } = (window as any).gbRawData.results || (window as any).gbRawData
  if (cat_info && searchKeywords && +searchKeywords.origin_total > 0) {
    const { keywords, search_id, search_cat } = cat_info
    let words = localStorage.getItem(`${lang}SearchHisotryWords`) as any

    try {
      words = JSON.parse(words) || []
    } catch (error) {
      words = []
    }

    words = words.filter(item => (isObject(item) ? keywords != (item as any).keywords : keywords != item))
    search_id ? words.unshift({ keywords, guideObj: { search_id, search_cat } }) : words.unshift(keywords)
    localStorage.setItem(`${lang}SearchHisotryWords`, JSON.stringify(words.slice(0, 10)))
  }
}

export function initHistory(lang) {
  searchRedirFun(lang)
  searchHistoryFun(lang)
}
