import { defineAsyncComponent } from 'vue'
const components = {
  // 单图
  StaticImage: defineAsyncComponent(() =>
    import(/* webpackChunkName: "ccc-static-image" */ '../components/StaticImage/Index.vue')),
  // 倒计时
  Countdown: defineAsyncComponent(() =>
    import(/* webpackChunkName: "ccc-countdown" */ '../components/CountDown/Index.vue')),
}

export default components
