<template>
  <div class="history-hot-words">
    <div
      v-if="historyWords.length"
      class="search-ctn-half">
      <div class="history-words-title">
        {{ language.SHEIN_KEY_PC_15867 || 'Recently Searched' }}
      </div>
      <div class="history-words-items">
        <div
          v-for="(item, index) in historyWords"
          :key="'history' + index"
          :class="['history-words-item', 'j-history-words-item', !showClearButton ? 'history-words-item-is-select' : '']"
          @click.stop="clickHistoryWords({ item, index })"
        >
          {{ item.keywords }}
          <span
            class="search-words__close-icon">
            <span v-show="isBigSearch && !showClearButton">
              <sui_icon_close_16px_1
                size="22px"
                style="line-height: 22px"
                @click.stop="clearCurSearchWord(index)"
              />
            </span>
          </span>
        </div>
      </div>
      <div
        v-if="showClearButton"
        class="history-words-clear">
        <sui_icon_delete_14px_1
          v-if="isBigSearch"
          size="14px"
          style="line-height: 14px"
          @click.stop="cleanHistoryWord"
        />
        <sui_icon_delete_16px_1
          v-else
          size="14px"
          style="line-height: 14px"
          @click.stop="cleanHistoryWord" />
      </div>
      <i
        v-else
        class="history-words-clear-done"
        @click.stop="closeClearBtn">
        {{ language.SHEIN_KEY_PC_25786 || 'Done' }}
      </i>
    </div>
    <div
      v-if="hotWords.length || hotImages.length"
      class="search-ctn-half">
      <div class="hot-words-title">
        {{ language.SHEIN_KEY_PC_19920 || 'Search Discovery' }}
      </div>
      <div
        class="hot-words-img"
        :class="{
          'hot-words-img2': !isBigSearch,
          'hasnot-historywords': !historyWords.length,
        }"
      >
        <div
          v-for="(item, index) in hotImages"
          :key="'hotImage' + index"
          class="hot-words-item_img j-hot-words-item"
          :title="item.word"
          @click="
            goSearchPage({
              keyword: item,
              result_type: item.from === 'ccc' ? 9 : 6,
              position: index + 1,
              wordTagType: item?.wordTagType,
            })
          "
        >
          <div class="padd-wrap">
            <img :src="item.imgSrc" />
            <div
              :class="['padd-icon-wrap', { 'flex-reverse': associationTagTotal !== 0 }]"
              :style="{ 'justify-content': associationTagTotal !== 0 ? 'center' : '' }"
            >
              <span
                class="search-hot__icon"
                :style="{ margin: associationTagTotal !== 0 ? '0 0 0 4px' : '' }">
                <sui_icon_hot_12px
                  size="14px"
                  style="line-height: 14px" />
              </span>
              <span
                class="padd-wrap-text"
                :style="{ 'text-align': associationTagTotal !== 0 ? 'center' : '' }">{{
                  item.word
                }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="hotWords.length"
        class="hotwords-item-ctn"
        :class="{ 'has-hotwords-img': hotImages.length || !historyWords.length }"
      >
        <div
          v-for="(item, index) in hotWords"
          :key="'hot' + index"
          class="j-hot-words-item hot-words-item active"
          :class="{
            'hot-words-item_active': item.active && !['trendword', 'trendstore'].includes(item.wordLabel?.labelType),
          }"
          :title="item.word"
          @click="
            goSearchPage({
              keyword: item,
              result_type: item.from === 'ccc' ? 9 : 6,
              position: index + 1 + hotImages.length,
              wordTagType: item?.wordTagType,
            })
          "
        >
          <p :class="['item-warp', { 'flex-reverse': associationTagTotal !== 0 }]">
            <template v-if="['trendword', 'trendstore'].includes(item.wordLabel?.labelType)">
              <b style="color: #9462ff; font-weight: 400">{{ item.word }}</b>
              <Icon
                name="sui_icon_search_trends_16px"
                size="12px"
                color="#9462FF"
                style="margin-right: 3px; line-height: 100%"
                class="word-hk-tag not-fsp-element"
              />
            </template>
            <template v-else>
              <span
                v-if="item.active"
                class="search-hot__icon search-hot__icon-words"
                :style="{ margin: associationTagTotal !== 0 ? '0 0 0 4px' : '' }"
              >
                <sui_icon_hot_12px
                  size="14px"
                  :style="{ 'line-height': '14px' }" />
              </span>
              {{ item.word }}
            </template>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Icon,
  sui_icon_close_16px_1,
  sui_icon_delete_14px_1,
  sui_icon_delete_16px_1,
  sui_icon_hot_12px,
} from '@shein-aidc/icon-vue3'

export default {
  name: 'HistoryHotWords',
  components: {
    Icon,
    sui_icon_close_16px_1,
    sui_icon_delete_14px_1,
    sui_icon_delete_16px_1,
    sui_icon_hot_12px,
  },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    historyWords: {
      type: Array,
      default: () => [],
    },
    hotWords: {
      type: Array,
      default: () => [],
    },
    hotImages: {
      type: Array,
      default: () => [],
    },
    showClearButton: {
      type: Boolean,
      default: false,
    },
    isBigSearch: {
      type: Boolean,
      default: false,
    },
    associationTagTotal: {
      type: Number,
      default: 0,
    },
  },
  emits: ['goSearchPage', 'hoverCloseIcon', 'clickHistoryWords', 'clearCurSearchWord', 'cleanHistoryWord', 'closeClearBtn'],
  data() {
    return {
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    goSearchPage(params) {
      this.$emit('goSearchPage', params)
    },
    clickHistoryWords({ item, index }) {
      this.$emit('clickHistoryWords', { item, index })
    },
    clearCurSearchWord(index) {
      this.$emit('clearCurSearchWord', index)
    },
    cleanHistoryWord() {
      this.$emit('cleanHistoryWord')
    },
    closeClearBtn() {
      this.$emit('closeClearBtn')
    },
  },
}
</script>

<style lang="less" scoped>
  .history-hot-words {
    color: #222222;
    position: relative;
    max-height: 520px;
    overflow-y: auto;
    padding: 10px 10px 0;
  }
  .history-words-title {
    margin-bottom: 10px;
    text-transform: capitalize;
    font-size: 18px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* rw:begin */
    font-family: 'Adieu';
  }
  .history-words-items {
    position: relative;
    display: flex;
    display: -ms-flexbox;
    -webkit-box-align: center;
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 14px;
  }
  .history-words-item {
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    word-break: break-word;
    color: #222;
    background: #f6f6f6;
    border-radius: 4px;
    margin-right: 12px;
    margin-bottom: 10px;
    padding: 8px 10px;

    line-height: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      background-color: #e9e9e9;
      color: #222;
    }
    .search-words__close-icon {
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 22px;
      height: 16px;
      width: 16px;
      color: #666;
      cursor: pointer;
      // &:hover {
      //   background-color: #e5e5e5;
      //   border-radius: 50%;
      //   color: #222;
      // }
    }
  }
  .history-words-item-is-select {
    padding: 8px 24px 8px 10px;
  }
  .history-words-clear {
    position: absolute;
    right: 10px;
    top: 14px;
    cursor: pointer;
    color: #666;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    font-size: 14px;
    display: inline-block;
    &:hover {
      background-color: #e1e1e1;
    }
  }
  .history-words-clear-done {
    position: absolute;
    right: 10px;
    top: 22px;
    cursor: pointer;
    color: #666;
    font-style: normal;
    min-width: 40px;
    height: 18px;
    line-height: 18px;
    font-size: 16px;
    text-align: center;
    color: #767676;
  }
  .hot-words-title {
    margin-top: 11px;
    margin-bottom: 16px;
    text-transform: capitalize;
    font-size: 18px;
    /* rw:begin */
    font-family: 'Adieu';
  }
  .hot-words-img {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3%;
    .hot-words-item_img {
      // flex: 1 0 40%;
      padding-top: 0;
      position: relative;
      margin-bottom: 10px;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        cursor: pointer;
        /*rw:begin*/
        border-radius: 6px;
      }
      &:before {
        /*sh:begin*/
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 1px solid rgba(0, 0, 0, 0.04);
        pointer-events: none;
        z-index: 1;
      }
      .padd-wrap {
        position: relative;
        width: 100%;
        padding-top: 100%;
        div {
          position: absolute;
          bottom: 0;
          left: 0 /*rtl:ignore*/;
          right: 0 /*rtl:ignore*/;
          background: rgba(255, 255, 255, 0.8);
          height: 30px;
          display: flex;
          align-items: center;
          cursor: pointer;
          z-index: 12;
          .sui_icon_nav_hot_14px {
            font-size: 14px;
            color: #f86262 /* rw:#FC4070*/;
          }
          .search-hot__icon {
            font-size: 14px;
            color: #f86262 /* rw:#FC4070*/;
          }
          .padd-wrap-text {
            max-height: 30px;
            font-size: 12px;
            line-height: 15px;
            color: #666;
            word-break: break-word;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
          }
        }
      }
      &:nth-of-type(2n) {
        margin-left: 10px;
      }
    }
  }
  .hot-words-img2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 0;
    .hot-words-item_img {
      flex: 1 0 40%;
    }
  }
  .hot-words-img-rw {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 0;
    .hot-words-item_img {
      flex: 1 0 40%;
    }
  }
  .hot-words-img .j-hot-words-item {
    max-height: 114px;
    width: 114px;
  }
  .hot-words-item {
    position: relative;
    line-height: 15px;
    background: #f5f5f5;
    padding: 4px 12px /*rtl:ignore*/;
    text-align: left /*rtl:ignore*/;
    margin-right: 10px;
    display: inline-block;
    margin-bottom: 10px;
    word-break: break-word;
    cursor: pointer;
    color: #666;
    padding: 8px 10px;
    &:hover {
      background-color: #e9e9e9;
      color: #222;
    }
    .item-warp {
      display: flex;
      align-items: center;
    }
    /*rw:begin*/
      line-height: 22px;
      border-radius: 16px;
      background: white;
      border: 1px solid rgb(229, 229, 229);
    .sui_icon_nav_hot_14px {
      font-size: 14px;
      color: #fa6338;
      margin: 0 4px 0 0;
    }
    .search-hot__icon {
      font-size: 14px;
      color: #fa6338;
      margin: 0 4px 0 0;
    }
    .search-hot__icon-words {
      height: 15px;
    }
    .search-hot__icon-rw {
      height: 22px;
    }
  }
  .hotwords-item-ctn{
    white-space: normal;
  }

  &.hidden {
    display: none;
  }

  .flex-reverse {
    flex-direction: row-reverse;
  }
</style>
