export const commonProps = {
  // 组件的位置
  index: {
    type: Number,
    default: 0,
  },
  context: {
    type: Object,
    default: () => ({}),
  },
  content: {
    type: Array,
    default: () => ([]),
  },
  propData: {
    type: Object,
    default: () => ({}),
  },
  language: {
    type: Object,
    default: () => ({}),
  },
}
