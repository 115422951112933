<template>
  <div
    class="bsc-mini-cart-crossed-price"
  >
    <span
      v-if="crossedPrice.description"
      class="bsc-mini-cart-crossed-price__description"
    >
      {{ crossedPrice.description }}
    </span>
    <span class="bsc-mini-cart-crossed-price__value">{{ crossedPrice.price.amountWithSymbol }}</span>
  </div>
</template>

<script name="BMiniCartCrossedPrice" setup lang="ts">
defineProps({
  crossedPrice: {
    type: Object,
    required: true,
  },
})
</script>

<style lang="less">
.bsc-mini-cart-crossed-price {
  flex-shrink: 9999;
  display: flex;
  align-items: center;
  color: #767676;
  overflow: hidden;
  .bsc-mini-cart-crossed-price__description {
    flex-shrink: 9999;
    margin-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .bsc-mini-cart-crossed-price__value {
    text-decoration: line-through;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
