<template>
  <div
    v-if="text"
    class="bsc-mini-cart-platform-shipping"
    :class="[
      'bsc-mini-cart-platform-shipping',
      {
        'bsc-mini-cart-platform-shipping_first': isFirstFreeShipping
      }
    ]"
  >
    <p
      class="bsc-mini-cart-platform-shipping__text"
      v-html="text"
    ></p>
    <MiniCartCountDown
      v-if="firstFreeShipping && firstFreeShipping.showCountDown && firstFreeShipping.countDownTime > 0"
      :time-stamp="firstFreeShipping.countDownTime"
      :is-left-time="true"
      :language="language"
    />
  </div>
</template>

<script name="BMiniCartPlatformShipping" setup lang="ts">
import { computed } from 'vue'
import MiniCartCountDown from './mini-cart-count-down.vue'

const props = defineProps({
  mallCode: {
    type: String,
    default: '',
  },
  mallShippingInfo: {
    type: Object,
    default: (() => {}),
  },
  showNewFreeShipping: {
    type: Boolean,
    default: false,
  },
  language: {
    type: Object,
    required: true,
  },
})

const shippingInfo = computed(() => {
  if(props.mallCode) return props.mallShippingInfo[`'${props.mallCode}'`] || null
  else return props.mallShippingInfo['platform'] || null
})

const newFreeShippingInfoTip = computed(() => {
  return props.showNewFreeShipping && shippingInfo.value?.freeShippingStrategy?.strategyPkData?.tip || ''
})

const firstFreeShipping = computed(() => { // 首单免邮
  return shippingInfo.value?.freeType == 1 ? shippingInfo.value || null : null
})

const shippingTip = computed(() => { // 平台型免邮文案
  return shippingInfo.value?.tip || ''
})

const isFirstFreeShipping = computed(() => {
  return !newFreeShippingInfoTip.value && !!firstFreeShipping.value
})

const text = computed(() => {
  return newFreeShippingInfoTip.value || firstFreeShipping.value?.tip || shippingTip.value
})
</script>

<style lang="less">
.bsc-mini-cart-platform-shipping {
  padding: 10px 20px;
  border-bottom: 1px solid #e5e5e5;
  color: #222;
  background: #F5FCFB;
  &.bsc-mini-cart-platform-shipping_first {
    color: #666;
    .bsc-mini-cart-platform-shipping__text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
