<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#666666"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00501 5.29999L6.72002 3H3V5H4.95255L5.26097 7.48912H5.27184L6.41135 16.5195H19.8786L21.2943 5.29999H7.00501ZM8.17483 14.5195L7.26384 7.29999H19.0261L18.1151 14.5195H8.17483Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.9 21.5001C8.72843 21.5001 9.4 20.8285 9.4 20C9.4 19.1716 8.72843 18.5 7.9 18.5C7.07157 18.5 6.4 19.1716 6.4 20C6.4 20.8285 7.07157 21.5001 7.9 21.5001Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.9 20C19.9 20.8285 19.2284 21.5001 18.4 21.5001C17.5716 21.5001 16.9 20.8285 16.9 20C16.9 19.1716 17.5716 18.5 18.4 18.5C19.2284 18.5 19.9 19.1716 19.9 20Z"
    />
  </svg>
</template>
