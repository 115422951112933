<template>
  <nav class="ske-navs">
    <div class="ske-navs-inner">
      <a v-for="i in 20" :key="i" class="ske-navs-item"></a>
    </div>
  </nav>
</template>
<style lang="less">
.ske-navs {
  padding: 0 45px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
  position: relative;
  overflow: hidden;
  border-bottom: 0.5px solid var(--sui-color-gray-weak-1, #e5e5e5);
  &-inner {
    height: 40px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &-item {
    height: 18px;
    width: 70px;
    margin: 0 14px;
    background-color: #f5f5f5;
    flex-shrink: 0;
  }
}
</style>
