import { ElementRects, Placement, Side, Alignment, Coords, Axis, Length, ComputePositionConfig } from '../../types'

export function getSide(placement: Placement): Side {
  return placement.split('-')[0] as Side
}

export function getAlignment(placement: Placement): Alignment {
  return placement.split('-')[1] as Alignment
}

function getSideAxis(placement: Placement): Axis {
  return ['top', 'bottom'].includes(getSide(placement)) ? 'y' : 'x'
}

function getOppositeAxis(axis: Axis): Axis {
  return axis === 'x' ? 'y' : 'x'
}

function getAlignmentAxis(placement: Placement): Axis {
  return getOppositeAxis(getSideAxis(placement))
}

function getAxisLength(axis: Axis): Length {
  return axis === 'y' ? 'height' : 'width'
}

export function computeCoordsFromPlacement(
  { reference, floating }: ElementRects,
  config: ComputePositionConfig,
): Coords {
  const { placement, isRTL = false, offsetX = 0, offsetY = 0 } = config
  const rtl = isRTL
  const sideAxis = getSideAxis(placement)
  const alignmentAxis = getAlignmentAxis(placement)
  const alignLength = getAxisLength(alignmentAxis)
  const side = getSide(placement)
  const alignment = getAlignment(placement)
  const isVertical = sideAxis === 'y'

  const commonX = reference.x + reference.width / 2 - floating.width / 2
  // const commonY = reference.y + reference.height / 2 - floating.height / 2
  const commonAlign = reference[alignLength] / 2 - floating[alignLength] / 2

  let coords: Coords
  switch (side) {
    case 'top':
      coords = { x: commonX + offsetX * (rtl && isVertical ? -1 : 1), y: reference.y - floating.height - offsetY }
      break
    case 'bottom':
      coords = { x: commonX + offsetX * (rtl && isVertical ? -1 : 1), y: reference.y + reference.height + offsetY }
      break
    // case 'right':
    //   coords = { x: reference.x + reference.width, y: commonY }
    //   break
    // case 'left':
    //   coords = { x: reference.x - floating.width, y: commonY }
    //   break
    default:
      coords = { x: reference.x, y: reference.y }
  }

  switch (alignment) {
    case 'start':
      coords[alignmentAxis] -= commonAlign * (rtl && isVertical ? -1 : 1)
      break
    case 'end':
      coords[alignmentAxis] += commonAlign * (rtl && isVertical ? -1 : 1)
      break
    default:
  }

  return coords
}
