<template>
  <div
    ref="headerFixerRef"
    class="bsc-common-header"
    :class="props.headerClass"
  >
    <slot name="content"></slot>
  </div>
</template>

<script name="BCommonHeader" setup lang="ts">
import { ref, defineEmits, onMounted, defineProps, defineExpose, provide } from 'vue'
import {
  C_CommonHeader,
  CommonHeaderEventProxyProvide,
  CommonHeaderContextProvide,
} from '../../types'
import { throttle } from '@shein/common-function'
import { ProxyHost } from '@shein-aidc/bs-sdk-libs-manager'
import { assignGlobalEventProxyHost } from '@shein-aidc/bs-sdk-libs-manager'

const commonHeaderEventProxy: C_CommonHeader.EventProxyHost =
  new ProxyHost<C_CommonHeader.Events>()
provide<C_CommonHeader.EventProxy>(
  'COMMON_HEADER_EVENT_PROXY_PROVIDE' as CommonHeaderEventProxyProvide,
  commonHeaderEventProxy,
)

const SCROLL_STATUS = {
  /** 滑动小于头部高度 */
  SMALLER_THAN_HEADER_HEIGHT: 1,
  /** 向下滚动 */
  SCROLL_DOWN: 2,
  /** 向上滚动 */
  SCROLL_UP: 3,
}

const headerFixerRef = ref<HTMLElement | null>(null)
const isShow = ref(true)
let beforeScrollTop = 0 // 上次滚动高度
let beforeScrollStatus // 上次滚动状态

provide('COMMON_HEADER_CONTEXT_PROVIDE' as CommonHeaderContextProvide, {
  isShow,
  hide: () => hideHeader(),
  show: () => showHeader(),
})


const props = defineProps({
  headerClass: {
    type: String,
    default: '',
  },
  preventScroll: {
    type: Function,
    default: () => {},
  },
  topHandle: {
    type: Function,
    default: () => {},
  },
  upHandle: {
    type: Function,
    default: () => {},
  },
  downHandle: {
    type: Function,
    default: () => {},
  },
})

const emit = defineEmits(['show-common-header'])

const getScrollStatus = (scrollDeltaVal) => {
  let $window = document.documentElement
  let afterScrollTop
  let scrollStatus = 0
  let scrollTop = (afterScrollTop = $window.scrollTop)
  let scrollDelta = scrollDeltaVal || afterScrollTop - beforeScrollTop
  beforeScrollTop = afterScrollTop

  let headerEl = headerFixerRef.value
  let headerElHeight = headerEl?.offsetHeight || 0
  if (scrollTop <= headerElHeight) {
    scrollStatus = SCROLL_STATUS.SMALLER_THAN_HEADER_HEIGHT
  } else if (scrollDelta > 0) {
    scrollStatus = SCROLL_STATUS.SCROLL_DOWN
  } else if (scrollDelta < 0) {
    scrollStatus = SCROLL_STATUS.SCROLL_UP
  }
  return scrollStatus
}

// 隐藏头部
const hideHeader = () => {
  let headerFixedEl = headerFixerRef.value
  if (!headerFixedEl) return

  let headerElHeight = headerFixedEl.offsetHeight
  headerFixedEl.classList.add('topscroll-hide')
  headerFixedEl.style.transform = 'translateY(-' + headerElHeight + 'px)'
  isShow.value = false
  emit('show-common-header', false)
}

// 显示头部
const showHeader = () => {
  let headerFixedEl = headerFixerRef.value
  if (!headerFixedEl) return
  headerFixedEl.classList.remove('topscroll-hide')
  headerFixedEl.style.transform = 'unset'
  isShow.value = true
  emit('show-common-header', true)
}

// 滚动处理
const pageScrollHandle = function(event, config) {
  const isPrevention = props.preventScroll()
  if (isPrevention) return

  const scrollStatus = getScrollStatus(config?.scrollDelta)

  // 相同状态不重复触发
  if (beforeScrollStatus === scrollStatus) return
  beforeScrollStatus = scrollStatus

  // 滑动小于头部高度
  if (scrollStatus === SCROLL_STATUS.SMALLER_THAN_HEADER_HEIGHT) {
    props.topHandle()
    showHeader()
    return
  }
  // 向上滚动
  if (scrollStatus === SCROLL_STATUS.SCROLL_UP) {
    const upPrevention = props.upHandle()
    if (upPrevention) return
    showHeader()
    return
  }
  // 向下滚动
  if (scrollStatus === SCROLL_STATUS.SCROLL_DOWN) {
    props.downHandle()
    hideHeader()
    return
  }
}

onMounted(() => {
  let $window = document.documentElement
  beforeScrollTop = $window.scrollTop
  // 监听滚动
  window.addEventListener('scroll', throttle({
    func: pageScrollHandle,
    wait: 200,
    options: {
      trailing: true,
    },
  }))
})

defineExpose({
  pageScrollHandle,
  show: showHeader,
  hide: hideHeader,
})

assignGlobalEventProxyHost('CommonHeader', commonHeaderEventProxy)

</script>

<style lang="less" scoped>
@zindex-category: 500;
.bsc-common-header {
  position: fixed;
  left: 0;
  width: 100%;
  transition: transform .3s;
  z-index: @zindex-category;
  min-height: 54px;
}
</style>
