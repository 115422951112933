<template>
  <div
    class="bsc-shein-club_badge"
    :style="{
      backgroundImage: `url(${IMG_LINK[noBorder ? 'prime-logo_bg' : cssRight ? 'personal-logo_ar' : 'personal-logo']})`,
      height: height,
      width: width,
    }"
  >
    <img
      v-if="noBorder"
      class="club_badge-logo"
      :class="{ 'no-border': noBorder }"
      :src="IMG_LINK['prime-logo']"
    />
  </div>
</template>

<script setup>

defineProps({
  noBorder: {
    type: Boolean,
    default: false,
  },
  cssRight: {
    type: Boolean,
    default: false,
  },
  height: {
    type: String,
    default: '18px',
  },
  width: {
    type: String,
    default: '86px',
  },
})

const IMG_LINK = {
  'prime-logo_bg': 'https://img.ltwebstatic.com/images3_ccc/2024/09/19/40/1726736359ae38c16825e639acb3af7f71876bb55a.webp',
  'prime-logo': 'https://img.ltwebstatic.com/images3_ccc/2024/09/19/a5/17267364657403b6fbb4d22b7ec1893c37a1b8635d.webp',
  'personal-logo': 'https://img.ltwebstatic.com/images3_ccc/2024/09/19/89/1726736609de1c597be46090a9c1d2e27edfd152c3.webp',
  'personal-logo_ar': 'https://img.ltwebstatic.com/images3_ccc/2024/09/19/e2/1726736667a55fab16d9353da63efbc87c7850e0ce.webp',
}
</script>

<style lang="less">
.bsc-shein-club_badge {
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;

  .club_badge-logo {
    width: 80%;
    margin-left: -10%;
  }
}
</style>
